import { createMap, createGoogleMap } from '../utilities/maps/maps.js';
import { createImportDropzone } from '../utilities/import.js';
import {
	changeVehicle,
	changeMultiVehicle,
	addVehicle,
	removeVehicle,
	validateVehicleChange,
	vehicleChangeQuestionnaire
} from './modules/vehicleChange.js';
import { TelephoneInput } from '../utilities/intl-tel-input.js';
import { doMapPage } from './modules/search.js';
import { doCategoryMapPage } from './modules/category.js';
import i18n from '../utilities/i18n.js';
import '../utilities/environment.js';
import './modules/my-bookings.js';
import '../utilities/custom-uploader.js';
import { trackCurrentTime } from './modules/track-current-time.js';

var cart = [];
var vehicleEmissions = {};
var isScratchcard = false;

var accountType = {name: 'accountType', value: '0'};
var usingGazeteer;
var disableManualAddress;
var canEditAddress;
var isManualAddress;
var usingEmailRestriction;
var isCreating;
var addresses;

var customTerms;
var iOSiPadOS;
var singlePrices;

let currencySymbol;
let initialPrice;
let smsFee;
let reminderPrice;
let finalPrice;
let scPrice;
var addressChangePermitIds;
var addressChangePackIds;

const ADDRESS_FIELDS = ['addressLine1', 'addressLine2', 'addressLine3', 'addressLine4', 'district', 'city', 'county', 'postCode', 'country'];

const PERIOD = {
	WEEK : 1,
	FORTNIGHT: 2,
	MONTH: 3,
	QUARTER: 4,
	YEAR: 5,
	HOUR: 6,
	DAY: 7
};

window.isMobile = (/Mobi|Android/i.test(navigator.userAgent))
	|| (/^iP/.test(navigator.platform) || /^Mac/.test(navigator.platform) && navigator.maxTouchPoints > 4);

Foundation.Abide.defaults.validators['emailDomain'] = ($el, required, parent) => {
	const domains = getEmailDomains();
	if (domains == null) {
		return true;
	}
	let valid = false;
	domains.forEach(domain => {
		domain = domain.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
		let re = new RegExp(`^.+${domain}$`, 'i');
		if (re.test($el.val())) {
			valid = true;
		}
	});
	return valid;
};

/**
 * Merges specific config with the core config providing i18n text plus more.
 */
function mergeSumoConfig(config = {})
{
	return $.extend({}, sumoConfig, config)
}

$(document).ready(function () {
/**
 * Shared admin site Javascript
 */

	/**
	 * Menu
	 */

	$('body')
		.on('click', 'svg', function(e) {
			const anchor = $(this).closest('a[data-toggle="sub-menu"]');
			if (anchor.length) {
				anchor.click();
			}
		})
		.on('click', '.menu-open, .menu-close, .overlay', toggleMenu)
		.on('click', '.nav-main', function(event) {
			if (window.innerWidth <= 1023) {
				if ($(event.target).data('toggle') != undefined) {
					event.preventDefault();
					const $li = $(event.target).parent();

					// If menuItemHasChildren is Expanded, Collapse It
					if ($li.hasClass('active')) {
						collapseSubMenu();
					} else {
						// Collapse Existing Expanded menuItemHasChildren
						if ($(this).find('.menu-item-has-children.active').length) {
							collapseSubMenu();
						}
						// Expand New menuItemHasChildren
						$li.addClass('active');
						const $ul = $li.find('ul.sub-menu');
						$ul.css('maxHeight', $ul.get(0).scrollHeight + 'px');
					}
				}
			} else if ($(event.target).data('toggle') != undefined) {
				// Desktop
				const $li = $(event.target).parent();
				$li.removeClass('focussed');
			}
		})
		.on('click', '.nav-account', function(event) {
			$(event.target).closest('li.menu-item').removeClass('focussed');
		})
		.on('focus', 'li.menu-item', function(e) {
			$('li.menu-item-has-children').not(this).removeClass('focussed');
		})
		.on('focus', 'li.menu-item-has-children', function(e) {
			$(this).addClass('focussed');
		})
		.on('keydown', 'li.sub-menu-item', function(e) {
			if (e.which == 37 || e.which == 39) {
				if ($('a:focus').length) {
					const $li = $('a:focus').parent('li');
					const data = {
						parent: { index: null, total: null },
						child: { index: null, total: null },
					}

					const $parent = $li.closest('li.menu-item');
					const $menus = $parent.parent('ul.nav-menu').find('li.menu-item');
					data.parent.total = $menus.length;
					data.parent.index = $menus.index($parent);

					const $items = $li.parent('ul.sub-menu').find('li');
					data.child.total = $items.length;
					data.child.index = $items.index($li);

					if (e.which == 37) {
						if (data.parent.index > 0) {
							$('ul.nav-menu li.menu-item').eq(data.parent.index - 1).children('a').focus();
							e.stopPropagation();
						}
					}
					if (e.which == 39) {
						if (data.parent.index < data.parent.total - 1) {
							$('ul.nav-menu li.menu-item').eq(data.parent.index + 1).children('a').focus();
							e.stopPropagation();
						}
					}
				}
			}
		})
		.on('keydown', '.nav-main li.menu-item', function(e) {
			if (e.which == 37 || e.which == 39) {
				if ($('a:focus').length) {
					const $li = $('a:focus').parent('li');
					const $items = $li.parent('ul.nav-menu').find('li.menu-item');
					const data = {
						parent: {
							index: $items.index($li),
							total: $items.length
						}
					}
					if (e.which == 37) {
						if (data.parent.index > 0) {
							$('ul.nav-menu li.menu-item').eq(data.parent.index - 1).children('a').focus();
						}
					}
					if (e.which == 39) {
						if (data.parent.index < data.parent.total - 1) {
							$('ul.nav-menu li.menu-item').eq(data.parent.index + 1).children('a').focus();
						}
					}
				}
			}
		})
		.on('keydown', 'li.menu-item-has-children.focussed', function(e) {
			if (e.which == 13 || e.which == 27 || e.which == 38 || e.which == 40) {
				if ($('a:focus').length) {
					const $li = $('a:focus').parent('li');
					const data = {
						parent: { index: null, total: null },
						child: { index: null, total: null },
					}
					if ($li.hasClass('menu-item')) {
						const $items = $li.parent('ul.nav-menu').find('li.menu-item');
						data.parent.total = $items.length;
						data.parent.index = $items.index($li);

						if (e.which == 40 || e.which == 13) {
							$li.find('li.sub-menu-item').eq(0).children('a').focus();
						}
					}
					if ($li.hasClass('sub-menu-item')) {
						const $parent = $li.closest('li.menu-item');
						const $menus = $parent.parent('ul.nav-menu').children('li.menu-item');
						data.parent.total = $menus.length;
						data.parent.index = $menus.index($parent);

						const $items = $li.parent('ul.sub-menu').children('li.sub-menu-item');
						data.child.total = $items.length;
						data.child.index = $items.index($li);

						if (e.which == 27) {
							$('li.menu-item').removeClass('focussed');
						}

						if (e.which == 38) {
							if (data.child.index == 0) {
								$parent.children('a').focus();
							} else {
								$items.eq(data.child.index - 1).children('a').focus();
							}
						}
						if (e.which == 40) {
							if (data.child.index == data.child.total - 1) {
								$menus.eq(data.parent.index + 1).children('a').focus();
							} else {
								$items.eq(data.child.index + 1).children('a').focus();
							}
						}
					}
				}
			}
		});

	// Open modal for accessibility
	$('body').on('click tap', '[name="signIn-pop"]', function(event) {
		$('#signinModal').foundation('open');
	});
	// Toggle Menu Function
	function toggleMenu() {
		$('.nav-main').toggleClass('active');
		$('.overlay').toggleClass('active');
		$('body').toggleClass('scrolling');

	}

	// Collapse Sub Menu Function
	function collapseSubMenu() {
		$('.nav-main').find('.menu-item-has-children.active .sub-menu').removeAttr('style');
		$('.nav-main').find('.menu-item-has-children.active').removeClass('active');
	}

	// Fixed Resize Screen Function
	function resizeScreen() {
		// If navbarMenu is Open, Close It
		if ($('.nav-main').hasClass('active')) {
			toggleMenu();
		}

		// If menuItemHasChildren is Expanded, Collapse It
		if ($('.nav-main').find('.menu-item-has-children.active').length) {
			collapseSubMenu();
		}
	}

	window.addEventListener('resize', () => {
		if (this.innerWidth > 1023) {
			resizeScreen();
		}
	});

	window.sumoConfig = {
		placeholder: i18n.__('buttons.select'),
		searchText: i18n.__('search'),
		captionFormat: i18n.__('sumoselect.caption.selected'),
		captionFormatAllSelected: i18n.__('sumoselect.caption.selected.all'),
		noMatch : i18n.__('sumoselect.unmatched'),
		locale :  [i18n.__('ok'), i18n.__('buttons.cancel'), i18n.__('buttons.select.all')],
		forceCustomRendering: true
	};

	iOSiPadOS = /^iP/.test(navigator.platform) || /^Mac/.test(navigator.platform) && navigator.maxTouchPoints > 4;

	// Not on a mobile device and not on apple mobile device
	if (!(/Mobi|Android/i.test(navigator.userAgent)) && !iOSiPadOS) {

		// ! DO NOT ALTER - SHARED CLASSES FOR GENERAL USE
		if ($('.sumo-select').length) {
			$('.sumo-select').SumoSelect(sumoConfig);
		}
		if ($('.sumo-select-search').length) {
			$('.sumo-select-search').SumoSelect(mergeSumoConfig({ search: true }));
		}
		if ($('.sumo-select-search-controls').length) {
			$('.sumo-select-search-controls').SumoSelect(
				mergeSumoConfig({ search: true, okCancelInMulti: true })
			);
		}
		if ($('.sumo-select-small').length) {
			$('.sumo-select-small').SumoSelect(sumoConfig);
			if ($('.sumo_reminderType').length) {
				$('.sumo_reminderType').attr('style', 'width:25%!important');
			}
		}
		if ($('.sumo-select-locale').length) {
			$('.sumo-select-locale').each(function () {
				$(this).SumoSelect(
					mergeSumoConfig({ up: $(this).closest('footer').length > 0 })
				);
			});
		}
	}

	$('select[name="locale').change(function(e) {
		$.post({
			url: '/applicant/locale',
			data: { locale: $(this).val() },
			dataType: 'json'
		}).done(response => {
			location.reload();
		});
	})

	window.distanceMeasurementUnit = $('#distance-unit').length ? $('#distance-unit').val() : 'imperial';
	window.componentRestrictions = $('#componentRestrictions').length ? $('#componentRestrictions').val() : 'gb';

	const pswmessages = [
		i18n.__('pswmeter.empty'),
		i18n.__('pswmeter.short'),
		i18n.__('pswmeter.weak'),
		i18n.__('pswmeter.medium'),
		i18n.__('pswmeter.strong'),
	];

	if ($("#pswmeter")[0]) {
		// div exists
		const myPassMeter = passwordStrengthMeter({
			containerElement: '#pswmeter',
			passwordInput: '#password',
			showMessage: true,
			messageContainer: '#pswmeter-message',
			borderRadius: 2,
			messagesList: pswmessages
		});
	}

	getCustomTerms();

	getAccountType();

	/**
	 * Ensures that a number input with the class "minmax" has a value within the min and max attributes set.
	 */
	$('body').on('change', 'input.minmax', function (e) {
		var value = $(this).val();
		if (value.length == 0) {
			return;
		}
		var min = $(this).attr('min');
		var max = $(this).attr('max');
		if (typeof min == 'undefined' || min.length == 0) {
			min = 0;
		}
		if (typeof max == 'undefined' || max.length == 0) {
			max = Number.MAX_SAFE_INTEGER;
		}
		value = Math.max(Math.min(value, max), min);
		$(this).val(value);
	});

	$('#bookings').on('click', 'a[data-open="reveal-permit-detail"]', function() {
		let id = $(this).data('id');
		getPermitDetails(id);
	});

	$('#bookings').on('click', 'a[data-open="reveal-scratchcard-detail"]', function() {
		let id = $(this).data('id');
		getScratchcardDetails(id);
	});

	$(document).on('closed.zf.reveal', '#reveal-permit-detail, #reveal-scratchcard-detail', function(e) {
		// Wipe the reveal so we don't hog element ids (permit reveal vs scratchcard reveal)
		$(this).empty();
	});

	$('body').on('click', '.use-credit-button', function(event) {
		const periodName = $(event.target).data('period-name');
		const periodNumber = $(event.target).data('period-number');
		$('#use-credit-help-text').html(
			i18n.__n(
				'portal.mybookings.scratchcard.credits.warning',
				periodNumber,
				{ name: periodName }
			)
		);
	});

	/**
	 * DATATABLES
	 */

	// Handle menu items show/hide
	$('body').on('menuitem.zt', 'a.permit-pay', function(e, row) {
		if ((row.menu & 1) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.permit-view', function(e, row) {
		if ((row.menu & 2) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.permit-change-multi', function(e, row) {
		if ((row.menu & 4) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.permit-add', function(e, row) {
		if ((row.menu & 8) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.permit-change', function(e, row) {
		if ((row.menu & 16) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.permit-renew', function(e, row) {
		if ((row.menu & 32) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.permit-pay-renew', function(e, row) {
		if ((row.menu & 64) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.permit-cancel', function(e, row) {
		if ((row.menu & 128) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.permit-auto-renewal-cancel', function(e, row) {
		if ((row.menu & 256) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.permit-remove', function(e, row) {
		if ((row.menu & 512) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.vehicle-pay', function(e, row) {
		if ((row.menu & 1024) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.permit-answer-questionnaire', function(e, row) {
		if ((row.menu & 2048) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.permit-reminder-view', function(e, row) {
		if ((row.menu & 4096) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.scratchcard-answer-questionnaire', function(e, row) {
		if ((row.menu & 8) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.scratchcard-pay', function(e, row) {
		if ((row.menu & 1) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.scratchcard-use', function(e, row) {
		if ((row.menu & 2) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.scratchcard-cancel', function(e, row) {
		if ((row.menu & 4) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.scratchcard-auto-redeeming', function(e, row) {
		if ((row.menu & 16) == 0) {
			$(this).parent().hide();
		}
		if (row.autoRedeeming) {
			$(this).addClass('disabled').prop('disabled', true);
		}
	});
	$('body').on('menuitem.zt', 'a.scratchcard-change-multi', function(e, row) {
		if ((row.menu & 32) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.scratchcard-add', function(e, row) {
		if ((row.menu & 128) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.scratchcard-change', function(e, row) {
		if ((row.menu & 64) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.scratchcard-remove', function(e, row) {
		if ((row.menu & 256) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.scratchcard-vehicle-pay', function(e, row) {
		if ((row.menu & 512) == 0) {
			$(this).parent().hide();
		}
	});
	$('body').on('menuitem.zt', 'a.scratchcard-reminder-view', function(e, row) {
		if ((row.menu & 1024) == 0) {
			$(this).parent().hide();
		}
	});

	if ($('#my-bookings-adaptive').length) {
		initMyBookingsPermitsTable();
		initMyBookingsScratchcardsTable();
	}

	$('#my-bookings-adaptive')
		.on('clearfilters.zt', 'a.clear-filters', function(e, target, serverSide) {
			if (serverSide) {
				api.column('locationId:name').search('');
			}
			let $filters = $('#' + target + '_filters');
			$filters.find('select[name="status"]')[0].sumo.selectItem(0);
			$filters.find('select[name="payment"]')[0].sumo.selectItem(0);
			$filters.find('select[name="tariff"]')[0].sumo.selectItem(0);
			$filters.find('select[name="site"]')[0].sumo.selectItem(0);
		})
		.on('change', 'select[name="status"]', function() {
			addDataTableDropdownFilter(this, 'status:name');
		})
		.on('change', 'select[name="payment"]', function() {
			addDataTableDropdownFilter(this, 'payment:name');
		})
		.on('change', 'select[name="tariff"]', function() {
			addDataTableDropdownFilter(this, 'tariffId:name');
		})
		.on('change', 'select[name="site"]', function() {
			addDataTableSiteFilter($(this));
		});

	$('body').on('bulkaction.zt', function(e, ids) {
		let calledAction = e.target.id;
		let targetTable = e.target.dataset.target;
		$('#details-error').html('').hide();

		$.ajax({
			url: '/applicant/applicantDatatableAction',
			type: 'POST',
			dataType: 'json',
			data: { calledAction, ids, targetTable },
			success: function(data) {
				if (data.error == false) {
					window.history.pushState(null, null, '/app/my-bookings');
					window.location.replace(data.url);
				} else {
					$('#details-error').html(data.msg).show();
					$('html, body').animate({ scrollTop: 0 }, 'slow');
				}
				$('#'+targetTable+'_actions').foundation('close');
			}
		});
	});

	$('#my_documents').ZatTable();
	$('body').on('menuitem.zt', 'a[data-menuitem-remove]', function(e, row) {
		if (row.isQuestionnaire) {
			$(this).parent().hide();
		}
	});

	/**
	 * DATATABLES (END)
	 */

	/**
	 * SHARING
	 */

	$(document)
		.on('closed.zf.reveal', '#sharing-request-modal', function() {
			$('#sharing-request-modal').find('span.form-error').hide();
			$('#request-email').val('');
			$('#sharing-add-request').prop('disabled', false);
		})
		.on('click', '#sharing-add-request', function(e) {
			$('#sharing-request-modal').find('span.form-error').hide();
			$('#sharing-add-request').prop('disabled', true);
			$.post({
				'url': '/app/sharing/add',
				'data': { email: $('#request-email').val() },
				'dataType': 'json'
			}).then((response) => {
				$('#sharing-add-request').prop('disabled', false);
				if (response.error) {
					$('#sharing-request-modal').find('span.form-error').html(response.msg).show();
					return;
				}
				$('#sharing-request-modal').find('span.form-error').hide();
				$('#sharing-requests').html(response.html);
				$('#request-email').val('');
				$('#sharing-request-modal').foundation('close');
			});
		})
		.on('click', '.sharing-request-cancel', function(e) {
			const id = $(this).data('request');
			$.post({
				'url': '/app/sharing/cancel',
				'data': { request: id },
				'dataType': 'json'
			}).then((response) => {
				if (response.error) {
					return;
				}
				$('#sharing-requests').html(response.html);
			});
		})
		.on('click', '.sharing-request-accept', function(e) {
			const id = $(this).data('request');
			$('#sharing-request-accept-confirm').data('request', id);
			$('#sharing-accept-modal').foundation('open');
		})
		.on('click', '#sharing-request-accept-confirm', function(e) {
			const id = $(this).data('request');
			const $expiry = $('select[name="request-expiry"]');
			$.post({
				'url': '/app/sharing/accept',
				'data': { request: id, expiry: $expiry.val() },
				'dataType': 'json'
			}).then((response) => {
				if (response.error) {
					return;
				}
				$('#sharing-delegates').html(response.html);
				$('#sharing-accept-modal').foundation('close');
			});
		})
		.on('click', '.sharing-request-decline', function(e) {
			const id = $(this).data('request');
			$.post({
				'url': '/app/sharing/decline',
				'data': { request: id },
				'dataType': 'json'
			}).then((response) => {
				if (response.error) {
					return;
				}
				$('#sharing-delegates').html(response.html);
			});
		})
		.on('click', '.sharing-request-revoke', function(e) {
			const id = $(this).data('request');
			$.post({
				'url': '/app/sharing/revoke',
				'data': { request: id },
				'dataType': 'json'
			}).then((response) => {
				if (response.error) {
					return;
				}
				$('#sharing-delegates').html(response.html);
			});
		});

	/**
	 * SHARING (END)
	 */

	// Navigate to the SSO provider URL for external authentication
	$('body').on('click tap', '[data-sso-btn]', function() {
		window.location.assign($(this).attr('data-sso-url'));
	});

	// If this user was redirected here as an incomplete profile, prompt for required
	const parsedUrl = new URL(window.location.href);
	if (parsedUrl.searchParams.get('incomplete') == 1) {
		$('#my-details input[type="submit"]').trigger('click');
	}

	$('#reveal-add-vehicle')
		.data('title', $('#reveal-add-vehicle').find('h5').text());
	$(document)
		.on('click tap', 'a.edit-vehicle', prefillVehicleForm)
		.on('closed.zf.reveal', '#reveal-add-vehicle', closeVehicleForm);

	/**
	 * Are we on the map search page? Could be /app/search or /applicant
	 */
	if ($('#map.map-enhanced').length) {
		doMapPage();
	}

	async function setupSearch(input)
	{
		const { Place } = await google.maps.importLibrary("places");

		selectFirstPredictionOnEnter(input);
		autocompleteObserver();

		// 2024-05-03 The PlaceAutocompleteElement is only in Preview at this time, so sticking with old style
		// https://developers.google.com/maps/documentation/javascript/place-autocomplete-new
		const searchBox = new google.maps.places.Autocomplete(
			input,
			{
				bounds: getGoogleBounds(),
				strictBounds: false,
				fields: ["geometry", "place_id"],
				componentRestrictions: { country: componentRestrictions.split(',') }
			}
		);

		searchBox.addListener("place_changed", () => {
			const place = searchBox.getPlace();
			/*
			let name = [place.name];
			$.each(place.address_components, function (_, component) {
				if (component.types.includes('postal_town')) {
					name.push(component.long_name);
				}
				if (component.types.includes('administrative_area_level_2')) {
					name.push(component.long_name);
				}
				if (component.types.includes('country')) {
					name.push(component.short_name);
				}
			});
			name = name.join(', ');
			*/
			name = $(input).val();
			document.location = '/app/search?place='
				+ encodeURIComponent(place.place_id)
				+ '&lat=' + place.geometry.location.lat()
				+ '&lng=' + place.geometry.location.lng()
				+ '&search=' + encodeURIComponent(name);
		});
	}

	/**
	 * Are we on the landing page?
	 */
	if ($('#landing-page').length) {

		/*
			Google Autocomplete
		*/
		const input = document.getElementById("landing-search");
		if (input) {
			setupSearch(input);
		}
	}

	if ($('#my-details').length || $('#create-account').length) {
		// https://github.com/jackocnr/intl-tel-input
		TelephoneInput.init();
		usingGazeteer = false;
		const $addressType = $('input[name="addressType"]');
		if ($addressType.length && $addressType.val() == 'llpg') {
			usingGazeteer = true;
		}
		usingEmailRestriction = false;
		const $emailRestriction = $('input[name="emailRestrictionDomains"]');
		if ($emailRestriction.length) {
			usingEmailRestriction = true;
		}

		if (usingEmailRestriction) {
			const domains = getEmailDomains();
			if (domains) {
				$('#email-error').html(i18n.__('portal.account.errors.domain'));
			}
		}

		disableManualAddress = false;
		canEditAddress = true;
		const $disableManualAddress = $('input[name="disableManualAddress"]');
		if ($disableManualAddress.length && $disableManualAddress.val() == '1') {
			disableManualAddress = true;
			canEditAddress = false;
		}

		if (disableManualAddress) {
			disableAddressFields();
		}

		isCreating = $('#create-account').length > 0;
	}

	if ($('#view-booking').length) {
		let bookingType = $('input[name="bookingType"]').val();
		let bookingId = $('input[name="bookingId"]').val();
		if (bookingType == 'scratchcard') {
			$.get({
				url: `/applicant/scratchcardReminderTable/${bookingId}`,
				dataType: 'json',
			}).done(handleReminderTable);
		}
	}

	$('#resend-email').on('click', function(event) {
		event.preventDefault();
		let button = $(this);
		let uid = button.data('uid');
		$.ajax({
			url: '/applicant/resendVerificationEmail',
			type: 'POST',
			dataType: 'json',
			data: { uid },
			beforeSend: function() {
				button.text(i18n.__('labels.please.wait')).attr('disabled',true);
			},
			success: function() {
				button.text(i18n.__('labels.resend.email')).attr('disabled', false);
			}
		});
	});
}); // END DOC READY

window.untokenise = function(text, data) {
	var matches = [...text.matchAll(/{(\w+)}/g)];
	if (matches == null) {
		return text;
	}
	$.each(matches, function(k, match) {
		let value = typeof data[match[1]] == 'undefined' ? '' : (data[match[1]] == null ? '' : data[match[1]]);
		text = text.replace(match[0], value);
	});
	return text;
};

Math.radians = function(degrees){
	return degrees * (Math.PI/180);
}

// see the Haversine formula description here
// https://community.esri.com/t5/coordinate-reference-systems/distance-on-a-sphere-the-haversine-formula/ba-p/902128
window.haversine = function(coord1, coord2) {
	// radius of earth in meters
	const R = 6371000;

	// convert latitude degrees to radians
	const phi_1 = Math.radians(coord1.lat());
	const phi_2 = Math.radians(coord2.lat());

	// delta being the "difference" between the latitudes and longitudes of each coordinate set expressed
	// in radians
	const delta_phi = Math.radians(coord2.lat() - coord1.lat());
	const delta_lambda = Math.radians(coord2.lng() - coord1.lng());

	// sin²(φB - φA/2) + cos φA * cos φB * sin²(λB - λA/2)
	const a = Math.sin(delta_phi/2.0)**2 + Math.cos(phi_1) * Math.cos(phi_2) * Math.sin(delta_lambda/2.0)**2;

	// 2 * atan2( √a, √(1−a) )
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

	const meters = R * c;
	const kilometers = meters / 1000.0;
	const miles = kilometers / 1.609344;

	return distanceMeasurementUnit == 'imperial' ? miles : kilometers; // { meters, kilometers, miles};
}

function initMyBookingsPermitsTable() {
	if ($('#mybookings_permits_table').length) {

		$('#mybookings_permits_table').ZatTable();
		$('#mybookings_permits_table').DataTable().draw();

		// Handle date picker with name "dates"

		const ranges = {};
		ranges[i18n.__('daterangepicker.ranges.today')] = [moment(), moment()];
		ranges[i18n.__n('daterangepicker.ranges.next', 7)] = [
			moment().subtract(6, 'days'),
			moment()
		];
		ranges[i18n.__('daterangepicker.ranges.month.current')] = [
			moment().startOf('month'),
			moment().endOf('month')
		];
		ranges[i18n.__('daterangepicker.ranges.month.next')] = [
			moment().add(1, 'month').startOf('month'),
			moment().add(1, 'month').endOf('month')
		];

		addDataTableDateFilter(
			'input[name="validTo"]',
			{
				'opens':'left',
				'maxDate':null,
				"ranges" : ranges
			}
		);
	}
}

function initMyBookingsScratchcardsTable() {
	$('#mybookings_scratchcards_table').ZatTable();
	$('#mybookings_scratchcards_table').DataTable().draw();
}

function getCustomTerms() {
	if ($('input[name=accounts]').length) {
		customTerms = JSON.parse($('input[name=accounts]').val());
	} else {
		$.ajax({
			url: '/applicant/getCustomTermsAjax/',
			type: 'GET',
			dataType: 'json',

			success: function(data) {
				if(data){
					customTerms = data;
				}
			}
		});
	}
}

function getAccountType() {
	const processAccountType = (data) => {
		let accountTypeSet = data.accountTypeSet;
		if (accountTypeSet == null) {
			return false;
		}
		if (accountTypeSet == 0){
			$('#accountTypeSet').foundation('open');
		}
	};
	if ($('input[name=accountType]').length) {
		let data = JSON.parse($('input[name=accountType]').val());
		processAccountType(data);
	} else {
		$.ajax({
			url: '/applicant/getAccountTypeSetAjax/',
			type: 'GET',
			dataType: 'json',
			success: processAccountType
		});
	}
}

function logoutInactive(data) {
	if(data.hasOwnProperty('logout') && data.logout) {
		setTimeout(() => {
			window.location.href = '/';
		}, 500);
	}
}

function getPermitDetails(id) {
	$('#reveal-permit-detail').html('');
	$.ajax({
		url: '/applicant/getPermitDetails/'+ id,
		type: 'GET',
		dataType: 'html',

		success: function(data) {
			if(data.error){
				logoutInactive(data);
				//err message
			} else {
				$('#reveal-permit-detail').html(data).foundation();
				loadMap();
			}
		}
	});
}

function getScratchcardDetails(id) {
	$('#reveal-scratchcard-detail').html('');
	$.ajax({
		url: '/applicant/getScratchcardDetails/'+ id,
		type: 'GET',
		dataType: 'html',

		success: function(data) {
			if(data.error){
				logoutInactive(data);
				//err message
			} else {
				$('#reveal-scratchcard-detail').html(data).foundation();
				loadMap();
			}
		}
	});
}

function setAutoRedeemingDefault(id, type)
{
	$.ajax({
		url: `/applicant/setAutoRedeemingDefault/${type}/${id}`,
		type: 'GET',
		dataType: 'html',

		success: function(data) {
			if(data.error){
				logoutInactive(data);
				//err message
			} else {
				$('body').trigger('autoRedeeming', [id]);
			}
		}
	});
}

function calculatePermit(selectedDate = null, $emissionsTariff = false) {
	if (!selectedDate) {
		return;
	}

	const $form = $('#permit-form').length ? $('#permit-form') : $('#scratchcard-form');

	let $permitPeriod = $form.find('input[name=permitPeriod]');

	if ($permitPeriod.length == 0 || $permitPeriod.val().length == 0) {
		return;
	}

	let formData = $form.serialize();

	$.ajax({
		url: '/app/calculate-permit',
		type: 'POST',
		dataType: 'json',
		timeout: '30000',
		data: formData,
		success: function(data) {
			let $errors = $('#permit-errors');

			if (data.error) {

				const RETROSPECTIVE_DATE_IN_PAST = 1;
				const DATE_IN_PAST = 2;

				if (data.error == RETROSPECTIVE_DATE_IN_PAST) {
					if ((data.validFromTime != '00:00:00') && (data.msg)) {
						$errors.find('.callout').html('<p>' + data.msg + '</p>');
						$errors.show();
					}
					return;
				} else {
					logoutInactive(data);
					return;
				}
			}

			// Restricting to user type as this was not here before, so don't want to bork what was (presumably) working
			if ($('input[name="locationType"]').val() == 'user') {

				$('.permitTotal').fadeIn('slow');
				$('#permit-total-price').parent().html(data.totalF);

				let total = data.total;
				let smsReminder = $('body').find('input[name="smsReminder"]').prop('checked');

				finalPrice = smsReminder ? total - parseFloat(data.smsFee) : total;
				reminderPrice = smsReminder ? total : total + parseFloat(data.smsFee);

				let displayPrice = smsReminder ? reminderPrice : finalPrice;

				$('#permit-total-price').parent().html(data.totalF);
			}

			$errors.hide().find('.callout').html('');

			let purchaseCount = 1;
			if ($('select[name="purchase-dropdown-permit"]').length && $('select[name="purchase-dropdown-permit"]').val()) {
				purchaseCount = $('select[name="purchase-dropdown-permit"]').val();
			}

			if (!$emissionsTariff) {
				initialPrice = parseFloat(data.initialPrice).toFixed(2);
				finalPrice = purchaseCount * initialPrice;
				reminderPrice = (parseFloat(finalPrice) + (parseFloat(smsFee) * purchaseCount));

				$('[data-target="permit-price"]').html(data.totalF);
				$('input[name="permitPrice"]').val(initialPrice);
				$('input[name="reminderPrice"]').val(reminderPrice);
			}

			// reserve the valid from element and whether it is visible
			let permitPeriodValidFrom = $('[data-target="permit-period-valid-from"]');
			let permitPeriodValidFromVisible = $(permitPeriodValidFrom).is(':visible');

			// if the data.expiresOn exists and the valid from is visible, populate the valid from element and fade in,
			// else, reset the content and fade it out
			if(typeof data.expiresOn !== 'undefined' && data.expiresOn != null && permitPeriodValidFromVisible){
				$('#permit-expires-on').html(data.expiresOn);
				$('[data-target="permit-period-valid-to"]').fadeIn('fast');
				handleActiveTimes(data.multiTimes);
			} else {
				$('#permit-expires-on').html('');
				$('[data-target="permit-period-valid-to"]').fadeOut('fast');
			}

			if ($('.datepicker').length) {
				const fp = $('.datepicker')[0]._flatpickr;

				if (data.forcedStartDate) {
					fp.set('minDate', data.forcedStartDate);
					fp.set('maxDate', data.forcedStartDate);
					fp.set('defaultDate', data.forcedStartDate);
					fp.set('enable', [data.forcedStartDate]);
					$('.datepicker').prop('disabled', true);
					$('.datepicker.flatpickr-mobile').attr('min', data.forcedStartDate);
				} else {
					$('.datepicker').prop('disabled', false);
				}
			}

			if ($('input[name="locationType"]').val() == 'sites'
				&& $('input[name="spaceLimit"]').val() != '' ) {
				let spaceLimit = $('input[name="spaceLimit"]').val();
				let spaceLeft = spaceLimit - data.activePermitsCount;
				spaceLeft = spaceLeft < 0 ? 0 : spaceLeft;
				$('body').find('#allocation-count').html(spaceLeft);
				$('body').find('#allocation-container').show();
			}

			if ($.isArray(data.availability)) {

				var availabilityMsg = '<strong>' + i18n.__('portal.purchase.spaces.following') + '</strong> ';

				$.each(data.availability, function(index, value) {
					availabilityMsg=availabilityMsg+' '+value+',';
				});

				availabilityMsg=availabilityMsg.slice(0, -1)+'.';

				$('#showAvailability').html(availabilityMsg).show();

				$('#permit-proceed-button').prop('disabled',true);
			} else {
				$('#showAvailability').html('').hide();
				$('#permit-proceed-button').prop('disabled',false);
			}

			if (data.hideCost == 1 && data.total == 0) {
				$('body').find('#permit-cost-container').hide();
			} else {
				$('body').find('#permit-cost-container').show();
			}
		},
		error: function(xhr, textStatus, errorThrown) {
			//called when there is an error
		}
	});
}

function handleActiveTimes(activeTimes) {
	let html = '';

	if (!activeTimes) {
		$('#permit-active-times').html('');
		$('[data-target="permit-period-active-times"]').fadeOut('fast');
		return;
	}

	html += '<p class="margin-0">';
	html += '<span class="has-tip" data-toggle="active-times">' + i18n.__('labels.active.times') + '</span>';
	html += '<div class="dropdown-pane" id="active-times" data-dropdown data-hover="true" data-hover-pane="true">';
	html += activeTimes;
	html += '</div>';
	html += '</p>';

	$('#permit-active-times').html(html).foundation();
	$('[data-target="permit-period-active-times"]').fadeIn('fast');
}

var flatpickrList = [];
function flatpickrReinit() {

	let dt = new Date();
	let hour = dt.getHours();
	let minute = dt.getMinutes();

	let ref;
	const flatdefaults = getFlatpickrDefaults();
	$.each($('.timepicker'), function (index, target) {
		ref = target.flatpickr(
			$.extend(
				{},
				flatdefaults,
				{ enableTime: true, noCalendar: true, time_24hr: true, dateFormat: 'H:i', minuteIncrement: 1, defaultHour: hour, defaultMinute: minute, disableMobile: true }
			)
		);
		flatpickrList.push(ref);
	});
	$.each($('.datepicker'), function (index, target) {
		ref = target.flatpickr(
			$.extend(
				{},
				flatdefaults,
				{ dateFormat: 'Y-m-d', altInput: true, altFormat: 'd/m/Y', defaultDate: 'today' }
			)
		);
		flatpickrList.push(ref);
	});
	$.each($('.datetimepicker'), function (index, target) {
		ref = target.flatpickr(
			$.extend(
				{},
				flatdefaults,
				{ enableTime: true, time_24hr: true, dateFormat: 'Y-m-d H:i', altInput: true, altFormat: 'd/m/Y H:i', minuteIncrement: 1, defaultDate: $(this).val(), defaultHour: hour, defaultMinute: minute }
			)
		);
		flatpickrList.push(ref);
	});
	$.each($('.datepicker-min-today'), function (index, target) {
		ref = target.flatpickr(
			$.extend(
				{},
				flatdefaults,
				{ dateFormat: 'Y-m-d', altInput: true, altFormat: 'd/m/Y', minDate: 'today', defaultDate: $(this).val() }
			)
		);
		flatpickrList.push(ref);
	});
	$.each($('.datetimepicker-min-today'), function (index, target) {
		ref = target.flatpickr(
			$.extend(
				{},
				flatdefaults,
				{ enableTime: true, dateFormat: 'd/m/Y H:i', altInput: true, altFormat: 'd/m/Y H:i', minDate: 'today', minuteIncrement: 1, time_24hr: true, defaultDate: $(this).val(), defaultHour: hour, defaultMinute: minute }
			)
		);
		flatpickrList.push(ref);
	});
}

var globalMinDate = 'today';

function tariffPeriodLookup(value) {
	var result;

	$.ajax({
		url: '/applicant/tariffPeriodLookup/',
		type: 'POST',
		dataType: 'json',
		async: false,
		data: {
			value : value,
			locationId : $('input[name="locationId"]').val(),
			locationType : $('input[name="locationType"]').val(),
		},
		success: function(data) {
			if(data.error == true) {
				logoutInactive(data);
			}
			result = data;
			globalMinDate = data.minDate;

			const fp = $('.datepicker')[0]._flatpickr;

			if (fp.minDate != data.minDate) {
				fp.set('minDate', data.minDate);
				$('.datepicker.flatpickr-mobile').attr('min', data.minDate);
			}
			if (fp.maxDate != data.maxDate) {
				fp.set('maxDate', data.maxDate);
				$('.datepicker.flatpickr-mobile').attr('max', data.maxDate);
			}
			if (data.forcedStartDate) {
				fp.set('enable', [data.forcedStartDate]);
				fp.set('defaultDate', data.forcedStartDate);
				fp.setDate(data.forcedStartDate, true, 'Y-m-d');
				fp.set('minDate', data.minDate);
				fp.set('maxDate', data.minDate);
				$('.datepicker').prop('disabled', true);
			} else {
				$('.datepicker').prop('disabled', false);
			}

			result = data;
		}
	});

	return result;
}

var formInvalid = false;

function getScratchcardInfo(id) {
	let locationId = $('input[name="scLocationId"]').val();

	$.ajax({
		url: '/applicant/getPackDetails/'+id+'/'+locationId,
		type: 'POST',
		dataType: 'json',

		success: function(resp) {
			if(resp.error == true) {
				logoutInactive(resp);
			}
			let msg = i18n.__n('portal.mybookings.scratchcard.credits.pack', resp.data.credits);
			if(resp.data.packLimit != null) {
				let additionalMessage = i18n.__n(
					'portal.purchase.scratchcard.limit',
					resp.data.packLimit,
					{ time: i18n.__n('labels.' + resp.data.limitType.toLowerCase() + 's.n', resp.data.limitTime) }
				);

				$('#scratchcard-info').html('<p>' + msg + '</p><p><strong>' + additionalMessage + '</strong></p>').show();
			} else {
				$('#scratchcard-info').html('<p>' + msg + '</p>').show();
			}

			$('#scratchcard-total-price').html('<strong>'+resp.data.currency+resp.data.price+'</strong>');
		}
	});
}

/**
 * Handles booking cancellation request
 * @param  {integer} id   [The id of the booking to be cancelled]
 * @param  {string}  type [The type of booking it is]
 */
function handleBookingCancellation(id, type) {
	let $callout = $('#confirm-cancel-booking-errors');

	$.when(
		cancelBooking(id, type)
	).done(function(data) {
		if (data.error) {
			$callout.html(data.msg).fadeIn();
			return;
		}

		$callout.fadeOut();
		$('#reveal-cancel-booking').foundation('close');

		if (data.error == false && data.approval == true) {
			// pop the approval modal instead
			let reveal = $('#cancel-permit-approval-reveal');
			reveal.find('[data-target="permit-cancel-questionnaire-container"]').html(data.questionnaire);
			reveal.find('input[name="bookingId"]').val(data.bookingId);
			reveal.find('input[name="locationId"]').val(data.locationId);
			reveal.find('input[name="locationType"]').val(data.locationType);
			reveal.find('input[name="permitType"]').val(data.permitType);
			initialiseQuestionnaire();
			reveal.foundation('open');
			return;
		}

		window.location.reload();
	}).fail(function(xhr, textStatus, errorThrown) {
		console.warn(errorThrown);
	});
}

/**
 * AJAX call to cancel a booking
 * @param  {[integer]} id   [The id of the booking to be cancelled]
 * @param  {[string]}  type [The type of booking it is]
 * @return {[json]} [The AJAX response]
 */
function cancelBooking(id, type) {
	return $.ajax({
		url: '/applicant/cancelBooking',
		type: 'POST',
		dataType: 'JSON',
		data: { id, type }
	});
}

function summaryFormSubmit() {
	$('#zatPermitErrors').hide();

	let button = $('#proceed-to-approval-button');
	let buttonValue = button.val();
	let formData = new FormData(document.getElementById('summaryForm'));

	if ($('body').find('#auto-renewal').length) {
		formData.append('auto-renewal', $('body').find('#auto-renewal').prop("checked") ? 1 : 0);
	}

	$.ajax({
		url: '/app/order-summary',
		type: 'POST',
		dataType: 'json',
		enctype: 'multipart/form-data',
		processData: false,
		contentType: false,
		data: formData,

		beforeSend: function(xhr, textStatus) {
			//called when complete
			button.val(i18n.__('labels.please.wait')).prop('disabled',true);
		},
		success: function(data, textStatus, xhr) {
			//called when successful
			if (data.error == true) {
				button.val(buttonValue).prop('disabled',false);
				logoutInactive(data);
				$('#zatPermitErrors').html(data.errorMsg).fadeIn('slow');

				$('html, body').animate({
					scrollTop: $('#zatPermitErrors').offset().top
				}, 800);
				setTimeout(function () {
					window.location = data.redirect;
				}, 5000);
			} else {
				window.location = data.redirect;
			}
		},
		error: function(xhr, textStatus, errorThrown) {
			//called when there is an error
			button.val(buttonValue).prop('disabled',false);
			$('#zatPermitErrors').html('Error: '+errorThrown).fadeIn('slow');
		}
	});
}

function scratchcardSummaryFormSubmit() {
	$.get({
		url: '/applicant/addScratchcardForRenewal',
		dataType: 'json',
		beforeSend: function() {
			$('#scratchcard-renewal-button').prop('disabled', true);
		}
	}).done(data => {
		if (data.error == true) {
			logoutInactive(data);
			$('#scratchcard-renewal-button').prop('disabled', false);

			$('#zatPermitErrors').html(data.errorMsg).show();

			$('html, body').animate({
				scrollTop: $('#zatPermitErrors').offset().top
			}, 800);
		} else {
			window.location = data.redirect;
		}
	});
}

$(document).ready(function() {
	$('body').on('mouseenter', '.text-truncate', function(){
		var $this = $(this);

		if(this.offsetWidth < this.scrollWidth && !$this.attr('title')){
			$this.attr('title', $this.text());
		}
	});

	if ($('body').find('[id*="map-canvas"]').length) {
		loadMap();
	}

	// TODO: Remove this when the new basket UI has been implemented
	// if there is only one scratchcard tariff to select (including the empty 'select' option)
	if ($('select[name="scratchcardPack"] option').length == 1) {
		let id = $('select[name="scratchcardPack"]').val();
		if (id) {
			// do ajax call to get pack details and price
			getScratchcardInfo(id);
		}
	}

	$('.purchase-detail input[name="smsReminder"], .purchase-detail input[name="emailReminder"]').on('change', function() {
		if ($('.purchase-detail input[name="smsReminder"]').prop('checked') || $('.purchase-detail input[name="emailReminder"]').prop('checked')) {
			$('[data-target="permit-reminder-time"]').fadeIn(200);
			$('[name="reminderTime"]').prop('disabled', false);

			if ($('.purchase-detail input[name="smsReminder"]').prop('checked')) {
				if (initialPrice != i18n.__('variable')) {
					$('[data-target="permit-price"]').html(currencySymbol + parseFloat(reminderPrice).toFixed(2));
				}
				$('[data-target="permit-reminder-sms"]').fadeIn(200);
			} else {
				$('[data-target="permit-reminder-sms"]').fadeOut(200);
				if (initialPrice != i18n.__('variable')) {
					$('[data-target="permit-price"]').html(currencySymbol + finalPrice);
				}
			}
		} else {
			$('[data-target="permit-reminder-time"], [data-target="permit-reminder-sms"]').fadeOut(200);
			$('[name="reminderTime"]').prop('disabled', true);
			if (initialPrice != i18n.__('variable')) {
				$('[data-target="permit-price"]').html(finalPrice == i18n.__('variable') ? finalPrice : currencySymbol + parseFloat(finalPrice).toFixed(2));
			}
		}
	});

	let dt = new Date();
	let hour = dt.getHours();
	let minute = dt.getMinutes();

	const flatdefaults = getFlatpickrDefaults();

	flatpickr(
		'.datepicker',
		$.extend({}, flatdefaults, { altFormat: 'd/m/Y', minDate: 'today', altInput: true, dateFormat: 'Y-m-d' })
	);

	flatpickr(
		'.datetimepicker',
		$.extend({}, flatdefaults, { enableTime: 'true', altFormat: 'd/m/Y H:i', minDate: 'today', altInput: true, dateFormat: 'Y-m-d H:i', defaultHour: hour, defaultMinute: minute, minuteIncrement: 1 })
	);

	flatpickr(
		'.timepicker',
		$.extend({}, flatdefaults, { enableTime: true, noCalendar: true, dateFormat: 'H:i', defaultHour: hour, defaultMinute: minute, disableMobile: true })
	);

	flatpickr(
		'.datetimepicker-min-today',
		$.extend({}, flatdefaults, { enableTime: true, dateFormat: 'd/m/Y H:i', altInput: true, altFormat: 'd/m/Y H:i', minDate: 'today', minuteIncrement: 1, time_24hr: true, defaultHour: hour, defaultMinute: minute })
	);

	$('#permit-valid-from-date').on('change', function() {
		if ($('body').find('[data-target="emissions-duration-dropdown"]').is(':visible')) {
			if ($('body').find('select[name="emissionsDuration"]').val() == '') {
				return;
			}
		}
		if ($('body').find('[data-target="tariff-duration-dropdown"]').is(':visible')) {
			if ($('body').find('[name="tariffDuration"]').val() == '') {
				return;
			}
		}
		calculatePermit($(this).val());
	});

	$('#permit-valid-from-time').on('click change', function() {
		if ($('body').find('[data-target="emissions-duration-dropdown"]').is(':visible')) {
			if ($('body').find('select[name="emissionsDuration"]').val() == '') {
				return;
			}
		}
		if ($('body').find('[data-target="tariff-duration-dropdown"]').is(':visible')) {
			if ($('body').find('[name="tariffDuration"]').val() == '') {
				return;
			}
		}
		// Act on the event
		let selected = $('#permit-valid-from-date').val();
		calculatePermit(selected);
	});

	if ($('#permit-form').length) {

		let locationType = $('#permit-form').find('input[name="locationType"]').val();

		if (locationType == 'user') {
			const flatdefaults = getFlatpickrDefaults();
			flatpickr(
				'.datepicker',
				$.extend(
					{},
					flatdefaults,
					{
						altFormat: 'd/m/Y',
						minDate: 'today',
						altInput: true,
						dateFormat: 'Y-m-d',
						mode: 'range',
						"disable": [
							function(date) {
								let data = $('#permit-valid-from-date').data();
								if (data.maxDate) {
									return moment(date).isAfter(data.maxDate) || moment(date).isBefore(data.minDate)
								} else {
									return false;
								}
							}
						],
						onChange: function(selectedDates, dateStr, fp) {
							// let maxDays = Number($('#maxDays').val());
							if (selectedDates.length == 1) {
								let maxDate = moment(selectedDates[0]).add(20, 'years').format('YYYY-MM-DD');
								$('#permit-valid-from-date').data('maxDate', maxDate);
								fp.set('maxDate', maxDate);
							}
						},
						onOpen: function(selectedDates, dateStr, fp) {
							let data = $('#permit-valid-from-date').data();
							if (data.maxOffset) {
								fp.set('minDate', data.minStart);
								fp.set('maxDate', data.maxStart);
								fp.clear();
							}
						}
					}
				)
			);
		}
	}

	$('select[name="vehicleType"]').on('change', function() {
		var vehicleTypeId = $(this).val();

		if($(this).parents('#reveal-use-scratchcard').length) {
			// We don't enforce vehicle makes on scratchcard credits
			return;
		}

		if(parseInt(vehicleTypeId)) {
			$.ajax({
				url: '/applicant/checkVehicleType/' + vehicleTypeId,
				type: 'POST',
				dataType: 'json',

				success: function(data) {
					//called when successful
					if(data.error == true) {
						logoutInactive(data);
					} else {
						let $vehicleMakeSelect = $('select[name="vehicleMake"]');
						if(data.makesRequired) {
							$vehicleMakeSelect.attr('required', 'required');
							if(formInvalid && $vehicleMakeSelect.find('option:selected').val() == '') {
								// If other fields are highlighted and this field has suddenly become required, we need to highlight this one now too.
								$vehicleMakeSelect.change();
							}
						} else {
							$vehicleMakeSelect.removeAttr('required');
							$vehicleMakeSelect.change();
						}

						$('#vehicle-type-warning').hide();
						$('#vehicle-details').show();
						$('input.vrm').attr('required', 'required');
					}
				}
			});
		} else {
			$('#vehicle-type-warning').show();
			$('#vehicle-details').hide();
			$('select[name="vehicleMake"]').removeAttr('required');
			$('input.vrm').removeAttr('required');
		}
	});



	$('[data-trigger="delete-vehicle-confirm"]').on('click', function(event) {
		var vehicleId = $('[data-vehicleId]').val();

		$.ajax({
			url: '/applicant/deleteVehicle/1',
			type: 'POST',
			dataType: 'json',
			data: { vehicleId : vehicleId },

			success: function(data, textStatus, xhr) {
				//called when successful
				if(data.error == true)
				{
					logoutInactive(data);
					$('#delete-vehicle-errors').html(data.errorMsg).fadeIn('slow');
				} else {
					// reload this
					window.location.reload();
				}
			}
		});
	});

	$('#vehicle-list').on('click', '[data-open="reveal-delete-vehicle"]', function(event) {
		event.preventDefault();
		var vehicleId = $(this).attr('rel');
		$('[data-vehicleId]').val(vehicleId);
		$('#reveal-delete-vehicle').foundation('open');
		let hasPermit = $(this).data('haspermit');
		if(hasPermit == true) {
			$('.delete-vehicle-error__haspermit').removeClass('hide');
			$('.delete-vehicle-error__nopermit').hide();

		} else {
			$('.delete-vehicle-error__haspermit').addClass('hide');
			$('.delete-vehicle-error__nopermit').show();
		}
	});

	$('body').on('click', '[data-open="reveal-add-vehicle"]', function(event) {
		event.preventDefault();
		$('[data-vehicleId]').val('');
	});

	//summaryForm
	$('input[name="summaryProceedBtn"]').on('click', function(event) {
		// Act on the event
		$('#zatPermitErrors').hide();
		var button = $(this);
		var buttonValue = button.val();

		$.ajax({
			url: '/app/order-summary',
			type: 'POST',
			dataType: 'json',
			data: $('#summaryForm').serialize(),
			beforeSend: function(xhr, textStatus) {
				//called when complete
				button.val(i18n.__('labels.please.wait')).attr('disabled',true);
			},
			success: function(data, textStatus, xhr) {
				//called when successful
				button.val(buttonValue).attr('disabled',false);
				if(data.error == true)
				{
					logoutInactive(data);

					$('#zatPermitErrors').html(data.errorMsg).fadeIn('slow');

					$('html, body').animate({
						scrollTop: $('#zatPermitErrors').offset().top
					}, 800);
				} else {
					//redirect to processing page
					window.location='/app/processing';
				}
			},
			error: function(xhr, textStatus, errorThrown) {
				//called when there is an error
				button.val(buttonValue).attr('disabled',false);
				$('#zatPermitErrors').html('Error: '+errorThrown).fadeIn('slow');
			}
		});
	});

	$('#proceed-to-approval-button').click(function(event) {
		Foundation.reInit('abide');
		// If there is an application form, submit that first then the order form
		if ($('#applicationForm').length > 0) {
			$('#applicationForm').submit();
		} else {
			$('#summaryForm').submit();
		}
	});

	/**
	 * ACTIVE BOOKINGS MODAL  - CHANGE VEHICLE
	 */

	$('#reveal-change-permit-vehicle').on('change', 'select[name="vehicle"]', function (event) {
		event.preventDefault();

		let $this = $(this);
		let $spinner = $('[data-spinner="spinner-change-vehicle"]');
		let $reveal = $('#reveal-change-permit-vehicle');
		let $errors = $('#change-permit-vehicle-errors');
		let data = '[data-new-vehicle]';
		let vehicleId = $(this).find('option:selected').val();

		if ((typeof vehicleId != 'undefined') && (vehicleId.length > 0)) {
			showVehicleInformation($spinner, $this, vehicleId, $reveal, data, $errors);
		}
	});

	$('#reveal-add-permit-vehicle-multi').on('change', 'select[name="vehicle"]', function (event) {
		event.preventDefault();

		let $this = $(this);
		let $spinner = $('[data-spinner="spinner-change-vehicle"]');
		let $reveal = $('#reveal-add-permit-vehicle-multi');
		let $errors = $('#reveal-add-permit-vehicle-multi-errors');
		let data = '[data-new-vehicle-multi]';
		let vehicleId = $(this).find('option:selected').val();

		if ((typeof vehicleId != 'undefined') && (vehicleId.length > 0)) {
			showVehicleInformation($spinner, $this, vehicleId, $reveal, data, $errors);
		}
	});

	$(window).on('closed.zf.reveal', function () {

		// reset change vehicle modal select on close event
		if ($('#reveal-change-permit-vehicle').length) {
			$('#reveal-change-permit-vehicle select[name="vehicle"]').val('');
			$('#reveal-change-permit-vehicle [data-new-vehicle]').hide().html('');
		}

		if ($('#reveal-delete-vehicle').length) {
			$('#reveal-delete-vehicle #vehicle-id').val('');
			$('#reveal-delete-vehicle #delete-vehicle-errors').hide().html('');
		}
	});


	$(document).on('closed.zf.reveal', '[data-add-vehicle-reveal]', function () {

		if($('form#add-vehicle .sumo-select-search').length >= 1){
			if (!(/Mobi|Android/i.test(navigator.userAgent))) {
				$.each($('form#add-vehicle select.sumo-select-search'), function(index, value) {
					$(value)[0].sumo.selectItem(0);
				});
			}
		}
		$('form#add-vehicle')[0].reset();

	});

	$(document).on('closed.zf.reveal', '[data-reveal-use-scratchcard]', function () {
		// if($('form#use-scratchcard-form .sumo-select-search').length){
		//  if (!(/Mobi|Android/i.test(navigator.userAgent))) {
		//      $.each($('form#use-scratchcard-form .sumo-select-search'), function(index, value) {
		//          $(value)[0].sumo.selectItem(0);
		//      });
		//  }
		// }
		$('form#use-scratchcard-form')[0].reset();

	});

	$(document).on('closed.zf.reveal', '[data-login-reveal]', function () {
		$('form#loginDetails')[0].reset();
	});

	/**
	 * ACTIVE BOOKINGS MODAL  - REMOVE VEHICLE
	 */
	 $('body').on('click', '[data-open="reveal-remove-permit-vehicle-multi"]', function() {
		var bookingId = $(this).attr('rel');
		var bookingTable = $(this).attr('data-table');
		$.ajax({
			url: '/applicant/multiVehicleChangeDropdowns',
			type: 'POST',
			dataType: 'json',
			data: {
				bookingId,
				bookingTable
			},
			success: function(data) {
				if(data.error == true) {
					logoutInactive(data);
				}

				$('#remove-assigned').html(data.assignedVehicles).data('count', data.assignedCount);
				$('#limit-warning-remove').html('').hide();

				$.when(
					checkPermitVehicleLimits(bookingId, bookingTable, 'removal')
				).then(function(data) {

					const updateDetails = vehicleUpdateModal(
						'remove',
						data.removalLimit,
						data.availableRemovals,
						data.vehicleRemovalApproval,
						data.pendingVehicleChangeAction // data.pendingVehicleRemoval
					);

					if ($('#remove-assigned').data('count') < 2) {
						updateDetails.error = true;
						updateDetails.message = i18n.__('portal.vehicles.remove.errors.all');
					}

					if (updateDetails.error) {
						$('button[data-trigger="remove-multi-vehicle-confirm"]').prop('disabled', true);
						$('#reveal-remove-permit-vehicle-multi select[name="assignedVehicle"]').prop('disabled', true);
					} else if (!isMobile) {
						let config = {
							renderLi: (li, originalOption) => li.attr('data-id', originalOption.parent().attr('name')+'-'+originalOption.val())
						}
						$('#reveal-remove-permit-vehicle-multi select[name="assignedVehicle"]').SumoSelect(mergeSumoConfig(config));
					}

					if (updateDetails.message) {
						$('#limit-warning-remove').html(updateDetails.message).addClass(updateDetails.classList).show();
					}
				})
				.done(function() {
					$('#reveal-remove-permit-vehicle-multi select[name="vehicle"]').trigger('change');
				})
				.fail(function(xhr, textStatus, errorThrown) {
					$('button[data-trigger="remove-multi-vehicle-confirm"]').prop('disabled', true);
					$('#reveal-remove-permit-vehicle-multi select[name="assignedVehicle"]').prop('disabled', true);
					$('#reveal-remove-permit-vehicle-multi-errors').html('Error: ' + errorThrown).fadeIn();
				});
			}
		});
		$('#reveal-remove-permit-vehicle-multi').data('booking-id', bookingId);
		$('#reveal-remove-permit-vehicle-multi').data('booking-table', bookingTable);
	});

	/**
	 * ACTIVE BOOKINGS MODAL  - CHANGE VEHICLE
	 */
	$('body').on('click', '[data-open="reveal-change-permit-vehicle"]', function() {
		var bookingId = $(this).attr('rel');
		var bookingTable = $(this).attr('data-table');
		var vehicleId = $(this).attr('data-vehicle') || false;

		$('#limit-warning').removeClass('warning alert').hide();
		$('#change-permit-vehicle-errors').hide();
		$('button[data-trigger="change-vehicle-confirm"]').prop('disabled', false);

		$.when(
			checkPermitVehicleLimits(bookingId, bookingTable, 'change')
		).then(function(data) {
			if (data.error == true) {
				logoutInactive(data);
			} else {
				$.when(
					getUnassignedVehiclesForMulti(bookingId, bookingTable)
				).then(function(html) {
					const $select = $('#reveal-change-permit-vehicle select[name="vehicle"]');
					$select[0].sumo.unload();
					$select.html(html);
					let config = {
						renderLi: (li, originalOption) => li.attr('data-id', originalOption.parent().attr('name')+'-'+originalOption.val())
					};
					$select.SumoSelect(mergeSumoConfig(config));
					$select[0].sumo.reload();
					if (($('#vehicleSummaryHeader').length == 0) && (false === $select.prop('disabled'))) {
						$select.change();
					}
					const updateDetails = vehicleUpdateModal(
						'change',
						data.tariffLimit,
						data.availableChanges,
						data.vehicleChangeApproval,
						data.pendingVehicleChangeAction // data.pendingVehicleChange
					);
					if (updateDetails.error) {
						$('button[data-trigger="change-vehicle-confirm"]').prop('disabled', true);
						$('#reveal-change-permit-vehicle select[name="vehicle"]').prop('disabled', true);
					} else {
						//$('#reveal-change-permit-vehicle select[name="vehicle"]')[0].sumo.reload();
					}
					if (updateDetails.message) {
						$('#limit-warning').html(updateDetails.message).addClass(updateDetails.classList).show();
					}
					let confirmText = i18n.__(data.questionnaire ? 'buttons.next' : 'buttons.confirm');
					$('#reveal-change-permit-vehicle').find('[data-questionnaire]').attr('data-questionnaire', data.questionnaire);
					$('#reveal-change-permit-vehicle').find('[data-questionnaire]').html(confirmText);

					$('#vehicle-change-questionnaire-reveal').find('input[name="locationId"]').val(data.locationId);
					$('#vehicle-change-questionnaire-reveal').find('input[name="locationType"]').val(data.locationType);
				});
			}
			return;
		}).done(function() {
			$('#reveal-change-permit-vehicle select[name="vehicle"]').trigger('change');
		}).fail(function(xhr, textStatus, errorThrown) {
			$('button[data-trigger="change-vehicle-confirm"]').prop('disabled', true);
			$('#reveal-change-permit-vehicle select[name="vehicle"]').prop('disabled', false);
			$('#change-permit-vehicle-errors').html('Error: ' + errorThrown).fadeIn();
		});

		if (vehicleId) {
			$('#reveal-change-permit-vehicle').data('booking-vehicle', vehicleId);
		}
		$('#reveal-change-permit-vehicle').data('booking-id', bookingId);
		$('#reveal-change-permit-vehicle').data('booking-table', bookingTable);
	});

	$('body').on('click', '[data-open="reveal-remove-permit-vehicle"]', function() {
		var bookingId = $(this).attr('rel');
		var bookingTable = $(this).attr('data-table');
		var vehicleId = $(this).attr('data-vehicle');

		$('#limit-warning-remove').html('').hide();

		$.when(checkPermitVehicleLimits(bookingId, bookingTable, 'removal')).then(function(data) {
			const updateDetails = vehicleUpdateModal(
				'remove',
				data.removalLimit,
				data.availableRemovals,
				data.vehicleRemovalApproval,
				data.pendingVehicleChangeAction // data.pendingVehicleRemoval
			);

			if ($('#remove-assigned').data('count') < 2) {
				updateDetails.error = true;
				updateDetails.message = i18n.__('portal.vehicles.remove.errors.all');
			}

			if (updateDetails.error) {
				$('button[data-trigger="remove-vehicle-confirm"]').prop('disabled', true);
			}

			if (updateDetails.message) {
				$('#limit-warning-remove').html(updateDetails.message).addClass(updateDetails.classList).show();
			}
		})
		.fail(function(xhr, textStatus, errorThrown) {
			$('button[data-trigger="remove-vehicle-confirm"]').prop('disabled', true);
			$('#reveal-remove-permit-vehicle-errors').html('Error: ' + errorThrown).fadeIn();
		});

		$('#reveal-remove-permit-vehicle').data('booking-id', bookingId);
		$('#reveal-remove-permit-vehicle').data('booking-table', bookingTable);
		$('#reveal-remove-permit-vehicle').data('booking-vehicle', vehicleId);
	});

	$('[data-trigger="remove-vehicle-confirm"]').on('click', function(e) {
		e.preventDefault();

		let $this = $(this);
		let vehicle = $('select[name="assignedVehicle"]').val();
		let bookingId = $('#reveal-remove-permit-vehicle').data('booking-id');
		let bookingTable = $('#reveal-remove-permit-vehicle').data('booking-table');
		let vehicleId = $('#reveal-remove-permit-vehicle').data('booking-vehicle');
		removeVehicle($this, vehicleId, bookingId, bookingTable);
	});

	$('body').on('click', '[data-open="reveal-add-permit-vehicle-multi"]', function() {
		var bookingId = $(this).attr('rel');
		var bookingTable = $(this).attr('data-table');
		$('#reveal-add-permit-vehicle-multi').data('booking-id', bookingId).data('booking-table', bookingTable);
	});

	$('[data-trigger="remove-multi-vehicle-confirm"]').on('click', function(e) {
		e.preventDefault();

		let $this = $(this);
		let vehicle = $('select[name="assignedVehicle"]').val();
		let bookingId = $('#reveal-remove-permit-vehicle-multi').data('booking-id');
		let bookingTable = $('#reveal-remove-permit-vehicle-multi').data('booking-table');
		removeVehicle($this, vehicle, bookingId, bookingTable);
	});

	$('[data-trigger="change-vehicle-confirm"]').on('click', function(e) {
		e.preventDefault();

		let $this = $(this);
		let vehicle = $('select[name="vehicle"]').val();
		let bookingId = $('#reveal-change-permit-vehicle').data('booking-id');
		let bookingTable = $('#reveal-change-permit-vehicle').data('booking-table');
		let vehicleId = $('#reveal-change-permit-vehicle').data('booking-vehicle');
		let questionnaire = $this.attr('data-questionnaire');
		let $reveal = $('#vehicle-change-questionnaire-reveal');

		// reset error
		$('#change-permit-vehicle-errors').html('').hide();

		if (questionnaire) {
			$.when(
				validateVehicleChange('change', vehicle, bookingId, bookingTable)
			).then(function(data) {
				if (data.error) {
					$('#change-permit-vehicle-errors').html(data.errorMsg).fadeIn();
					return;
				}

				return vehicleChangeQuestionnaire(bookingId, bookingTable, 'change');
			}).done(function(questionnaire) {
				if (questionnaire == 'error') {
					return;
				}

				if (questionnaire == 'logout') {
					logoutInactive({ logout:true });
				}

				if (!questionnaire) {
					if (vehicleId) {
						changeMultiVehicle($this, vehicleId, vehicle, bookingId, bookingTable);
					} else {
						changeVehicle($this, vehicle, bookingId, bookingTable);
					}
					return;
				}

				$('#reveal-change-permit-vehicle').foundation('close');

				$reveal.find('[data-target="vehicle-change-questionnaire-container"]').html(questionnaire);
				$reveal.find('input[name="bookingId"]').val(bookingId);
				$reveal.find('input[name="bookingTable"]').val(bookingTable);
				$reveal.find('input[name="newVehicle"]').val(vehicle);
				if (vehicleId) {
					$reveal.find('input[name="oldVehicle"]').val(vehicleId);
				}

				initialiseQuestionnaire();

				$reveal.foundation('open');
			});

			return;
		}

		if (vehicleId) {
			changeMultiVehicle($this, vehicleId, vehicle, bookingId, bookingTable);
		} else {
			changeVehicle($this, vehicle, bookingId, bookingTable);
		}
	});

	$('[data-trigger="add-multi-vehicle-confirm"]').on('click', function(e) {
		e.preventDefault();
		let $this = $(this);
		let selected = $('#reveal-add-permit-vehicle-multi select[name="vehicle"]').find('option:selected');
		let vehicleId = selected.val();
		var bookingId = $('#reveal-add-permit-vehicle-multi').data('booking-id');
		var bookingTable = $('#reveal-add-permit-vehicle-multi').data('booking-table');
		let questionnaire = $this.attr('data-questionnaire');
		let $reveal = $('#vehicle-change-questionnaire-reveal');

		// reset error
		$('#reveal-add-permit-vehicle-multi-errors').html('').hide();

		if (questionnaire) {
			$.when(
				validateVehicleChange('addition', vehicleId, bookingId, bookingTable)
			).then(function(data) {
				if (data.error) {
					$('#change-permit-vehicle-errors').html(data.errorMsg).fadeIn();
					return;
				}

				return vehicleChangeQuestionnaire(bookingId, bookingTable, 'addition');
			}).done(function(questionnaire) {
				if (questionnaire == 'error') {
					return;
				}

				if (questionnaire == 'logout') {
					logoutInactive({ logout:true });
				}

				if (!questionnaire) {
					addVehicle($this, vehicleId, bookingId, bookingTable);
					return;
				}
				$('#reveal-add-permit-vehicle-multi').foundation('close');

				$reveal.find('[data-target="vehicle-change-questionnaire-container"]').html(questionnaire);
				$reveal.find('input[name="bookingId"]').val(bookingId);
				$reveal.find('input[name="bookingTable"]').val(bookingTable);
				$reveal.find('input[name="vehicle"]').val(vehicleId);
				$reveal.find('input[name="purpose"]').val('Vehicle Addition');
				$reveal.find('h5').text(i18n.__('portal.questionnaire.purpose.addition.title'));

				initialiseQuestionnaire();

				$reveal.foundation('open');
			});

			return;
		}
		addVehicle($this, vehicleId, bookingId, bookingTable);
	});

	$('input[name="create-account"]').on('click', function(event) {
		event.preventDefault();
		window.location.replace('/app/create-account');
	});


	$('[data-open="reveal-terms-and-conditions"]').on('click', function(event) {
		event.preventDefault();

		$.ajax('/applicant/termsAndConditions')
			.done(function(resp){
				$('#terms-and-conditions').html(resp);
				$('#reveal-terms-and-conditions').foundation('open');
			}
			);
		return false;
	});

	$('[data-open="reveal-privacy-policy"]').on('click', function(event) {
		event.preventDefault();

		$.ajax('/applicant/privacyPolicy')
			.done(function(resp){
				$('#privacy-policy').html(resp);
				$('#reveal-privacy-policy').foundation('open');
			}
			);
		return false;
	});

	$("input#search").keydown(function (event) {
		if (event.keyCode == 13) {
			event.preventDefault();
		}
	});

	var timeout;
	$('input#search, #carParkSearch button[type="reset"]').on('keyup change click', function() {
		var filter = $(this).val();

		clearTimeout(timeout);

		timeout = setTimeout (function () {
			$.ajax({
				url: '/applicant/search',
				type: 'POST',
				dataType: 'html',
				data: { 'search': filter },

				success: function(data) {
					//called when successful
					$('#carpark-list').html(data);
					Foundation.reInit('equalizer');
				}
			})
		},500);
	});

	$('input[name="signIn"]').on('click', function(event) {
		event.preventDefault();
		$.ajax({
			url: '/applicant/login',
			type: 'POST',
			dataType: 'json',
			data: $('#loginDetails').serialize(),

			success: function(data) {
				//called when successful
				if(data.error == true) {
					$('#login-errors').html(data.errorMsg).show();
				} else {
					if (data.verified == 1) {
						window.location.reload();
					} else {
						window.location.href = '/app/verify';
					}
				}
			}
		});
	});

	$('[data-abide]').on('submit', function(event) {
		// Do nothing so that the validation can be used
		event.preventDefault();
	});

	$('button[data-open="reveal-map-canvas"]').on('click', function(event){
		event.preventDefault();

		$('#reveal-map-canvas').foundation('open');

		return false;
	});

	$('a[data-trigger="data-address"]').on('click', function(event) {
		event.preventDefault();
		event.stopPropagation();

		$(this).hide();
		$('div[data-address]').removeClass('hide');
		$('input[name="addressLookupPostcode"]').prop('required', false).removeClass('is-invalid-input');
	});

	$('body').on('click', '#manual-address', function() {
		disableAddressFields(false);
		$('div[data-address]').removeClass('hide');
	});

	$('body').on('change', 'input[data-address]', function () {
		isManualAddress = true;
		if ($('#addressLookupSelect').isSumo()) {
			$('#addressLookupSelect')[0].sumo.selectItem(0);
		}
	});

	$('body').on('change', 'select[name="country"]', function () {
		isManualAddress = true;
		if ($('#addressLookupSelect').isSumo()) {
			$('#addressLookupSelect')[0].sumo.selectItem(0);
		}
	});

	const addressLookupGetAddress = function (postcode) {
		$.ajax({
			url: '/applicant/addressLookup', // Change this
			type: 'POST',
			dataType: 'json',
			data: { postcode : postcode },

			beforeSend: function() {
				$('div[data-abide-error]').hide();
			},

			success: function(data) {
				if(!data.error) {
					$('#addressList').html(data.dropdown).show();
					// Foundation.reInit('abide');
					if (!(/Mobi|Android/i.test(navigator.userAgent))) {
						$('body').find('select[name="addressLookupSelect"]').SumoSelect(mergeSumoConfig({search: true}));
					}
					addresses = data.addresses;
				} else {
					logoutInactive(data);
					$('#addressList').hide();
					$('.sumo_addressLookupSelect').remove();
					$('#addresslookuperror').text(data.msg).show();
					$('#addressAddManual').show();
				}
			}
		});
	};

	$('input[data-trigger="address-lookup"]').on('click', function (event) {
		event.preventDefault();
		event.stopPropagation();
		$('#addresslookuperror').text('').hide();

		let postcode;
		if($('input[name="addressLookupPostcode"]').length > 0) {
			postcode = $('input[name="addressLookupPostcode"]').val().trim();
		} else {
			postcode = $('input[name="postCode"]').val().trim();
		}

		if (postcode.length == 0) {
			return;
		}

		if (isCreating) {
			$('div[data-address]').addClass('hide');
		}

		clearAddressFields();
		addressLookupGetAddress(postcode);
	});

	const addressLookupSelect = function(value, $select, $option) {
		if (value == '') {
			return;
		}
		let fieldArr = [ 'addressLine1', 'addressLine2', 'addressLine3', 'addressLine4', 'ignore', 'city', 'county' ];

		let addressArray = value.split(',');

		updateField('district', '');
		let i = 0;
		fieldArr.forEach(function(field) {
			if(field != 'ignore') {
				updateField(field, addressArray[i]);
			}
			i++;
		});

		updateField('postCode', $select.attr('rel'));

		$('select[name="country"]').val(232);
		$('select[name="country"]')[0].sumo.reload();

		disableAddressFields(disableManualAddress);

		$('div[data-address]').removeClass('hide');
		$('a[data-trigger="data-address"]').hide();
		$('input[name="addressLookupPostcode"]').prop('required', false);
	}

	const addressLookupGazeteerSelect = function(value, $select, $option) {
		if (value == '') {
			if (addresses.length == 0 || isManualAddress) {
				return;
			}
			for (var property in addresses[0]) {
				let $inputs = $('div[data-address]').find('input[name="' + property + '"]');
				if ($inputs.length) {
					$inputs.val('');
				}
			}
			$('select[name="country"]')[0].sumo.selectItem('');
			return;
		}
		const address = addresses[$option.data('index')];
		if (typeof address == 'undefined') {
			return;
		}
		for (var property in address) {
			let $input = $('div[data-address]').find('input[name="' + property + '"]');
			if ($input.length) {
				updateField(property, address[property] == null ? '' : address[property]);
			}
		}
		$('select[name="country"]').val(232);
		$('select[name="country"]')[0].sumo.reload();

		disableAddressFields();
		if (false === disableManualAddress) {
			$('#addressAddManual').show();
		}

		$('div[data-address]').removeClass('hide');
		$('a[data-trigger="data-address"]').hide();
		$('input[name="addressLookupPostcode"]').prop('required', false);
	}

	$('html, body').on('change', 'select[name="addressLookupSelect"]', function(event) {
		event.stopPropagation();
		let $addressLookupSelect = $(this);
		let $selected = $addressLookupSelect.find('option:selected');
		let value = $selected.val();
		let type = $addressLookupSelect.data('type');
		if (value.length) {
			isManualAddress = false;
		}
		if (type == 'llpg') {
			canEditAddress = false;
			addressLookupGazeteerSelect(value, $addressLookupSelect, $selected);
		} else {
			canEditAddress = !disableManualAddress;
			addressLookupSelect(value, $addressLookupSelect, $selected);
		}
	});

	function updateField(fieldName, fieldValue) {
		let $input = $('input[name="'+fieldName+'"]');
		$input.val(fieldValue.trim());

		if(fieldValue != '') {
			$input.removeClass('is-invalid-input');
			$input.siblings('.form-error').removeClass('is-visible').hide();
			$('.is-invalid-label').removeClass();
		}
	}

	$('select[name="scratchcardPack"]').on('change', function(event) {
		let id = $(this).val();
		$('#scratchcard-info').html('').hide();
		$('#scratchcard-total-price').html('<strong>&pound;0.00</strong>');
		// if selection not empty
		if(id) {
			// do ajax call to get pack details and price
			getScratchcardInfo(id);
		}
	});

	$('body').on('click', '[data-open="reveal-use-scratchcard"]', function(event) {
		event.preventDefault();
		var scratchcardId = $(this).data('id');
		// json request to get the reveal contents
		$.ajax({
			url: `/applicant/getScratchcardRevealContent`,
			type: 'POST',
			dataType: 'json',
			data: { scratchcardId },

			beforeSend: function() {
				$('body').find('#use-scratchcard-errors').html('').hide();
			},
			success: function(data) {
				$('body').find('#credit-config-container').html(data.html);
				sumoEm();
				flatpickrReinit();
				calculateCreditExpiry();

				// Set min and max dates
				const $startDate = $('input[name="startDate"]');
				const fp = $startDate[0]._flatpickr;
				if (fp != undefined) {
					fp.set('minDate', $startDate.data('min') ? $startDate.data('min') : null);
					fp.set('maxDate', $startDate.data('max') ? $startDate.data('max') : null);
				}

				// Keep time updated to current time unless manually set
				trackCurrentTime('input[name="startDate"]', 'input[name="startTime"]');

				$('body').find('#scratchcard-id').val(scratchcardId);
				$('body').find('#radioManualInput').prop('checked', true).trigger("change");
				$('#reveal-use-scratchcard').foundation('open');
			}
		});
	});

	$('input[name="continueScratchcard"]').on('click', function(event) {
		event.preventDefault();

		$.ajax({
			url: '/applicant/freeScratchcardConfirmation/',
			type: 'POST',
			dataType: 'html',

			beforeSend: function() {
				$('input[name="continueScratchcard"]').prop('disabled', true);
			},
			success: function(data) {
				window.location.href = '/app/free-approved';
			}
		});
	});

	$('#scratchcard-renewal-button').on('click', function(event, form) {
		isScratchcard = true;
		Foundation.reInit('abide');
		// If there is an application form, submit that first then the order form
		if ($('#applicationForm').length > 0) {
			$('#applicationForm').submit();
		} else {
			$('#scratchcardSummaryForm').submit();
		}
	});

	$('#scratchcard-approval-button').on('click', function(event, form) {
		isScratchcard = true;
		let $this = $(this);
		let status = $this.attr('data-status');
		let redirectUrl = $this.attr('data-redirect');
		let formData;

		$.ajax({
			url: `/applicant/addScratchcardForApproval/${status}`,
			type: 'POST',
			dataType: 'json',
			enctype: 'multipart/form-data',
			processData: false,
			contentType: false,
			data: formData,

			beforeSend: function() {
				$this.prop('disabled', true);
			},
			success: function(data) {
				//called when successful
				if (data.error == true) {
					logoutInactive(data);
					$this.prop('disabled', false);

					$('#zatPermitErrors').html(data.errorMsg).show();

					$('html, body').animate({
						scrollTop: $('#zatPermitErrors').offset().top
					}, 800);
				} else {
					window.location = redirectUrl;
				}
			}
		});
	});

	$('body').on('click', '[data-open="reveal-close-account"]', function(event) {
		// Clear dialog before use
		$('#close-account-errors').hide();
		$('#close-account-success').hide();
		$('input[name="purgePassword"]').val('');
	});

	$('input[type="file"][multiple]').on('change', function() {
		let $form = $(this).parents('form').first();
		let maxFiles = $(this).data('max-files');

		if(this.files.length > maxFiles) {
			$form.foundation('addErrorClasses', $(this));
			$(this).siblings('span.form-error').text(i18n.__n('portal.mydocuments.errors.max', maxFiles));
		} else {
			// $form.foundation('addErrorClasses', $(this));
			$(this).siblings('span.form-error').text(
				i18n.__('portal.uploads.upload.type', { type: $(this).attr('placeholder') })
			);
		}
	});

	$('input[name="submitPayment"]').on('click', function(event) {
		event.preventDefault();
		$('#ecom6Payment').submit();
	});

	$('input[name="saveSettings"]').on('click', function(e) {
		e.preventDefault();
		let active = 0;
		if($('#marketing').is(':checked')) {
			active = 1;
		}

		$.ajax({
			url: '/applicant/saveMarketingPreference/',
			type: 'POST',
			dataType: 'json',
			data: { active : active },
			success: function (data) {
				if(data.error == true){
					logoutInactive(data);
				}
			},
			complete: function(data) {
				$('#settings-success').html(i18n.__('portal.settings.saved')).fadeIn().delay(2000).fadeOut();
			}
		});
	});

	$('#information-box').on('click', function(){
		let dismissedState = true;

		$.ajax({
			url: '/applicant/saveDismissedBoxSetting/',
			type: 'POST',
			dataType: 'json',
			data: { dismissedState : dismissedState },
			success: function (data) {
				if(data.error == true){
					logoutInactive(data);
				}
			}
		});
	});

	// User wants to cancel their booking so let's confirm that
	$('body').on('click tap', '[data-trigger="cancel-booking"]', function(event) {
		event.preventDefault();

		let id = $(this).attr('data-id');
		let type = $(this).attr('data-type');
		let $reveal = $('#reveal-cancel-booking');
		let $cancelButton = $reveal.find('[data-trigger="confirm-cancel-booking"]');

		$('#confirm-cancel-booking-errors').hide();
		$cancelButton.attr('data-id', id);
		$cancelButton.attr('data-type', type);

		$reveal.foundation('open');
	});

	// User has confirmed cancellation of booking so let's cancel it
	$('body').on('click tap', '[data-trigger="confirm-cancel-booking"]', function(event) {
		event.preventDefault();

		let id = $(this).attr('data-id');
		let type = $(this).attr('data-type');

		handleBookingCancellation(id, type);
	});

	$('body').on('click tap', '[data-open="reveal-auto-redeem"]', function(event) {
		event.preventDefault();
		let id = $(this).data('id');
		let type = $(this).data('type');
		let $reveal = $('#reveal-auto-redeem');
		$reveal.data('id', id);
		$reveal.data('type', type);
	});

	$('body').on('click tap', '[data-trigger="confirm-auto-redeem"]', function(event) {
		event.preventDefault();
		let $reveal = $('#reveal-auto-redeem');
		let id = $reveal.data('id');
		let type = $reveal.data('type');
		setAutoRedeemingDefault(id, type);
		$('a.auto-redeeming-tariff').removeClass('disabled').prop('disabled', false);
		$(`#${type}-auto-redeeming-${id}`).addClass('disabled').prop('disabled', true);
		$reveal.foundation('close');
		if ($('#view-booking').length) {
			window.location.reload();
		}
	});

	$('body').on('change', 'select[name="purchase-dropdown-permit"]', function() {
		let purchaseCount = $(this).val();
		let reveal = $('#location-permit-detail');

		let id = reveal.find('[data-target="permit-period"]').val();
		let isEmissions = reveal.find('[data-target="permit-emission-based"]').val();
		let vehiclesDropdown = reveal.find('[data-target="vehicles-dropdown"]');

		$.when(
			getApplicantVehicles(id, isEmissions)
		).done(function(html) {
			if (html != '' && purchaseCount > 0) {
				let dropdown = '<strong>' + i18n.__('labels.assign.vehicle') + '</strong>';
				for(var i = 0; i < purchaseCount; i++) {
					dropdown += html;
				}

				vehiclesDropdown.html(dropdown);
				if (!(/Mobi|Android/i.test(navigator.userAgent)) && !iOSiPadOS) {
					vehiclesDropdown.find('select').SumoSelect(sumoConfig);
				}
				vehiclesDropdown.show();

				reveal.find('.sumo_vehicle').addClass('margin-bottom-1');

				// if not emissions based do a calculation of the cost at this point
				if (isEmissions == 'false' && !isNaN(initialPrice)) {
					finalPrice = (initialPrice * purchaseCount).toFixed(2);
					reminderPrice = (parseFloat(finalPrice) + (parseFloat(smsFee) * purchaseCount));

					if ($('body').find('input[name="smsReminder"]').prop('checked')) {
						$('[data-target="permit-price"]').html(currencySymbol + parseFloat(reminderPrice).toFixed(2));
					} else {
						$('[data-target="permit-price"]').html(currencySymbol + parseFloat(finalPrice).toFixed(2));
					}
				}

				if (isEmissions == 'true') {
					$('body').find('[data-target="emissions-duration-dropdown"]').show();
				}
			} else {
				vehiclesDropdown.html('').hide();
				finalPrice = initialPrice;
				reminderPrice = (parseFloat(finalPrice) + parseFloat(smsFee));
				$('[data-target="permit-price"]').html((finalPrice != i18n.__('variable') ? currencySymbol : '') + finalPrice);

				if (isEmissions == 'true') {
					$('body').find('[data-target="emissions-duration-dropdown"]').hide();
				}
			}
		}).fail(function(xhr, textStatus, errorThrown) {
			console.warn(errorThrown);
		});
	});

	$('body').on('change', 'select[name="purchase-dropdown-scratchcard"]', function() {
		let purchaseCount = $(this).val();

		let tempPrice = parseFloat(scPrice.substring(1, scPrice.length)).toFixed(2);
		currencySymbol = scPrice.slice(0,1);

		if (purchaseCount > 0 && !isNaN(tempPrice)) {
			// just find out the price and multiply for each count
			$('[data-target="scratchcard-price"]').html(currencySymbol + (tempPrice * purchaseCount).toFixed(2));
		} else {
			// set the original price back
			$('[data-target="scratchcard-price"]').html(scPrice);
		}
	});

	$('body').on('click tap', '[data-trigger="answer-questionnaire"]', function(event) {
		event.preventDefault();
		let id = $(this).attr('data-id');
		let type = $(this).attr('data-type');

		$.ajax({
			url: '/applicant/getManualPermitQuestionnaire/'+id+'/'+type,
			type: 'POST',
			dataType: 'json',
			success: function (data) {
				if(data.error == true){
					window.location.reload();
				} else {
					let reveal = $('#pending-manual-questionnaire-reveal');
					reveal.find('[data-target="manual-questionnaire-container"]').html(data.questionnaire);
					reveal.find('input[name="bookingId"]').val(data.bookingId);
					reveal.find('input[name="bookingType"]').val(type);
					reveal.find('input[name="locationId"]').val(data.locationId);
					reveal.find('input[name="locationType"]').val(data.locationType);
					initialiseQuestionnaire();
					reveal.foundation('open');
				}
			}
		});
	});

	$('body').on('click tap', '[data-trigger="manual-questionnaire-submit"]', function(event) {
		event.preventDefault();

		let button = $(this);
		button.prop('disabled', true);
		let $form = $('#manual-questionnaire-form');
		let permitId = $form.find('input[name="bookingId"]').val();
		let bookingType = $form.find('input[name="bookingType"]').val();
		let cartArr = [];
		let formData = new FormData(document.getElementById('manual-questionnaire-form'));
		let cart  = {};


		cart.permitId = permitId;
		cart.bookingType = bookingType;
		cart.locationId = $form.find('input[name="locationId"]').val();
		cart.locationType = $form.find('input[name="locationType"]').val();
		cart.application = {};

		for (var entry of formData.entries()) {
			if (!cart.application.hasOwnProperty(entry[0])) {
				cart.application[entry[0]] = entry[1];
			} else {
				var entryArr = [];

				// If its already an array loop it and push elements
				if (Array.isArray(cart.application[entry[0]])) {
					for (var subEntry of cart.application[entry[0]]) {
						entryArr.push(subEntry);
					}
				} else {
					entryArr.push(cart.application[entry[0]]);
				}

				entryArr.push(entry[1]);
				cart.application[entry[0]] = entryArr;
			}
		}

		// Add any missing checkboxes because they weren't checked
		$("#manual-questionnaire-form input:checkbox").each(function() {
			if (!cart.application.hasOwnProperty(this.name)) {
				cart.application[this.name] = '';
			}
		});
		cartArr.push(cart);
		formData.append('cart', JSON.stringify(cartArr));
		formData.append('type', bookingType);
		formData.append('noReservedId', true);
		formData.append('purpose', 'Application');

		$.when(
			submitQuestionnaire(formData)
		).done(function(data) {
			if (data == 'logout') {
				logoutInactive({ logout:true });
				return;
			}

			let $errors = $('#manual-questionnaire-errors');

			if (data.error) {
				// Show error messages
				$errors.find('.callout').html(data.msg);
				$errors.show();

				// Add error labels
				$.each(data['error-list'], function(ele, msg) {
					$('body').find(`[data-target="${ele}-error-label"]`).addClass('is-invalid-label');
				});
				button.prop('disabled', false);
				return;
			}

			if (typeof data[0] != 'undefined') {
				updateManualApplication(permitId, bookingType, data[0].applicationId);
			} else {
				window.location.reload();
			}

		}).fail(function(xhr, textStatus, errorThrown) {
			button.prop('disabled', false);
			console.warn(errorThrown);
		});
	});

	function updateManualApplication(bookingId, bookingType, applicationId) {
		$.ajax({
			url: '/applicant/updateManualApplication/',
			type: 'POST',
			dataType: 'json',
			data: { bookingId, bookingType, applicationId },

			success: function(data) {
				$('body').find('#confirmation-msg').html(data.msg).show();
				setTimeout(function () {
					window.location.reload();
				}, 5000);
			}
		});
	}

	$('body').on('change', '#email-reminder, #sms-reminder', function() {
		if ($(this).is(':checked')) {
			getDefaultReminder();
		}
	});

	$('body').on('change', '#reminderTime', function() {
		$('#reminderTime').data('changed', 1);
	});

	$('body').on('change', '#reminderType', function(e, b) {
		var min, max;
		const $newTime = $('#reminderTime');
		if ($(this).val() == '') {
			$('#b4-remaining').show();
			$('#b4-expiry').hide();
			const credits = $newTime.data('credits');
			min = 0;
			max = credits - 1;
			$newTime.attr('min', min);
			$newTime.attr('max', max);
		} else {
			$('#b4-remaining').hide();
			$('#b4-expiry').show();
			min = 1;
			max = Number.MAX_SAFE_INTEGER;
			$newTime.attr('min', min);
			$newTime.removeAttr('max');
		}
		const time = $newTime.val();
		$newTime.val(Math.min(Math.max(min, time), max));
		$('#reminderTime').data('changed', 1);
	});

	$('#reveal-use-scratchcard').on('change', 'input[name="startDate"], input[name="startTime"]', function() {
		let date = $('input[name="startDate"]').val();
		let time = $('input[name="startTime"]').val();
		let startDate = date + ' ' + time;
		let scratchcardId = $('#scratchcard-id').val();

		$.ajax({
			url: '/applicant/checkSpacesScratchcard/',
			type: 'POST',
			dataType: 'json',
			data: { startDate, scratchcardId },

			success: function(data) {
				if (data.error == false) {
					$('body').find('#space-limit-info').html('<p><strong>'+data.msg+'</strong></p>').show();
				}
			}
		});
	});

	$('body').on('click tap', 'input[name="confirm-address-change-cancel"]', function(event) {
		let button = $(this);
		$.ajax({
			url: '/applicant/processAddressChangeCancellations/',
			type: 'POST',
			dataType: 'json',
			data: { permitIds : addressChangePermitIds, packIds : addressChangePackIds },

			beforeSend: function() {
				button.prop('disabled', true);
			},
			success: function(data) {
				if (data.error == false) {
					$('body').find('#reveal-address-change-cancellation').foundation('close');
					$('#my-details').submit();
				}
			},
			complete: function() {
				button.prop('disabled', false);
			}
		});
	});

	$('body').on('change', '[name="inputChoice"]', function() {
		const isManual = $(this).val() === 'manual';
		$('#manual-input-container').toggle(isManual);
		$('#account-vehicle-container').toggle(!isManual);

		const formElements = {
			type: $('select[name="vehicleType"]'),
			make: $('select[name="vehicleMake"]'),
			model: $('input[name="scModel"]'),
			colour: $('select[name="vehicleColour"]')
		};

		Object.values(formElements).forEach(function(element) {
			if (element.is('input')) {
				element.prop('disabled', !isManual);
				if (!isManual) {
					element.val('');
				}
			} else {
				element.prop('disabled', !isManual).each(function() {
					isManual ? this.sumo.enable() : this.sumo.disable();
				});
				if (!isManual) {
					element[0].sumo.unSelectAll();
				}
			}
		});

		sumoEm();
	});



})
// When a field element is invalid...
	.on('invalid.zf.abide', function(event,field) {
		if ($('select.sumo-select-search, select.sumo-select').hasClass('is-invalid-input')) {
			field.siblings('.CaptionCont.SelectBox').addClass('is-invalid-input').css({ 'border-color': '#e7484f', 'background-color': '#fceced', 'color': '#e7484f' });
		}
	})
// When a field element is valid...
	.on('valid.zf.abide', function(event,field) {
		// field.siblings('span.form-error').first().html('');
		if ($('.CaptionCont.SelectBox').hasClass('is-invalid-input')) {
			field.siblings('.CaptionCont.SelectBox').removeClass('is-invalid-input').removeAttr('style');
		}
	})
// When a field failed validation
	.on('invalid.zf.abide', function(event,el) {
		if (['addressLine1', 'city', 'postCode', 'country'].includes($(el).attr('name'))) {
			$('div[data-address]').removeClass('hide');
		}
	})
// When the form failed validation...
	.on('forminvalid.zf.abide', function(event,form) {
		formInvalid = true;
	})
// When the form passed validation...
	.on('formvalid.zf.abide', function(event,form) {
	// Account creation form
		if(form.attr('name') == 'create-account') {

			var accountData = $('#create-account').serializeArray();
			accountData.push(accountType);
			// accountData.push({ name: 'usingGazeteer', value: usingGazeteer ? 1 : 0 });
			accountData.push({
				name: 'addressLookupSelect',
				value: $('[name="addressLookupSelect"').val()
			});
			let $inputs = $('div[data-address]').find('input');
			$inputs.each(function () {
				let name = $(this).attr('name');
				if (ADDRESS_FIELDS.includes(name)) {
					accountData.push({
						name: $(this).attr('name'),
						value: $(this).val()
					});
				}
			});
			accountData.push({
				name: 'country',
				value: $('select[name="country"').val()
			});
			accountData.push({
				name: 'telephone_full',
				value: $('input[name=telephone').intlTelInput('getNumber')
			});
			accountData.push({
				name: 'mobile_full',
				value: $('input[name=mobile').intlTelInput('getNumber')
			});
			accountData.push({
				name: 'addressType',
				value: $('select[name="addressLookupSelect"]').data('type')
			});
			// if (usingGazeteer) {
			// 	accountData.push({
			// 		name: 'postCode',
			// 		value: $('select[name="addressLookupSelect"]').val()
			// 	});
			// }

			$.ajax({
				url: '/applicant/createAccount',
				type: 'POST',
				dataType: 'json',
				data: accountData,

				success: function(data) {
					//called when successful
					if(data.error == true) {
						logoutInactive(data);
						let individualErrors = false;
						for (const key in data.errors) {
							if (data.errors.hasOwnProperty(key)) {
								individualErrors = true;
								const msg = data.errors[key];
								let $el = $('[name="' + key + '"]');
								if ($el.length) {
									$el.siblings('span.form-error').first().html(msg);
									$el.siblings('.CaptionCont.SelectBox').addClass('is-invalid-input').css({ 'border-color': '#e7484f', 'background-color': '#fceced', 'color': '#e7484f' });
									$('#create-account').foundation('addErrorClasses', $el);
								}
								$el = $(`[data-form-error-for="${key}"]`);
								$el.html(msg);
							}
						}
						if(!individualErrors) {
							$('#creation-errors').html(data.msg).show();
						} else {
							$('#creation-errors').html('<p>' + i18n.__('errors.form') + '</p>').show();
						}
					} else {
						if (data.verified == 0) {
							window.location.href = '/app/verify';
						} else {
							$('#creation-success').html(data.msg).show();
							setTimeout(function () {
								window.location.href = '/app/my-bookings';
							}, 5000);
						}
					}
				}
			});
		}

		if ($(document.activeElement).attr('name') === 'llpg-submit') {
			event.preventDefault(); // This will stop the form from submitting immediately
			addressChangePermitIds = null;
			addressChangePackIds = null;
			let addressId = $('body').find('[name="addressLookupSelect"]').val();
			let stopProcess = false;

			$.ajax({
				url: '/applicant/addressChangeCheck',
				type: 'POST',
				dataType: 'json',
				data: { addressId },
				async: false,

				success: function(data) {
					if (data.error) {
						stopProcess = true;
						$('body').find('#address-change-cancel-container').html(data.html);
						addressChangePermitIds = data.permitIds;
						addressChangePackIds = data.packIds;
						$('body').find('#reveal-address-change-cancellation').foundation('open');
					}
				}
			});

			if (stopProcess) {
				return;
			}
		}

		// Account details editing form
		if(form.attr('name') == 'my-details') {

			var accountData = $('#my-details').serializeArray();
			accountData.push({ name: 'telephone_full', value: $('input[name=telephone').intlTelInput('getNumber') });
			accountData.push({ name: 'mobile_full', value: $('input[name=mobile').intlTelInput('getNumber') });
			accountData.push({ name: 'usingGazeteer', value: usingGazeteer ? 1 : 0 });
			accountData.push({
				name: 'addressLookup',
				value: $('select[name="addressLookupSelect"').val()
			});
			accountData.push({
				name: 'addressType',
				value: $('select[name="addressLookupSelect"]').data('type')
			});

			if (disableManualAddress) {
				ADDRESS_FIELDS.forEach(field => {
					const input = field == 'country' ? 'select' : 'input';
					$(`${input}[name="${field}"]`).each(function () {
						if ($(this).is('[data-address]') || input == 'select') {
							accountData.push({
								name: field,
								value: $(this).val()
							});
						}
					})
				});
			}

			$.ajax({
				url: '/applicant/myDetails',
				type: 'POST',
				dataType: 'json',
				data: accountData,
				beforeSend: function() {
					$('span.form-error').hide();
					$('#details-errors').hide();
					$('#details-success').hide();
					$('input[type="submit"]').prop('disabled', true);

				},
				success: function(data) {
					if (data.error == true) {
						logoutInactive(data);

						let individualErrors = false;
						for (const key in data.errors) {
							if (data.errors.hasOwnProperty(key)) {
								individualErrors = true;
								const msg = data.errors[key];
								let $el = $('[name="' + key + '"]');
								$el.siblings('span.form-error').first().html(msg);
								$('#my-details').foundation('addErrorClasses', $el);
							}
						}

						if (!individualErrors) {
							$('#details-errors').html(data.msg).show();
						} else {
							$('#details-errors').html('<p>' + i18n.__('errors.form') + '</p>').show();
						}
					} else {
						$('.username').html($('#input-email').val());
						$('#details-success').html(data.msg).show();

						//
						const parsedUrl = new URL(window.location.href);
						if (parsedUrl.searchParams.get('incomplete')) {
							window.location.href = '/app/my-details';
						}
					}
					$('input[type="submit"]').prop('disabled', false);
				}
			});
		}
		// Password change form
		if(form.attr('name') == 'change-password') {
			$.ajax({
				url: '/applicant/changePassword',
				type: 'POST',
				dataType: 'json',
				data: $('#change-password').serialize(),

				beforeSend: function() {
					$('#change-password-errors').hide();
					$('#change-password-success').hide();
					$('input[type="submit"]').prop('disabled', true);
				},

				success: function(data) {
					if(data.error == true) {
						logoutInactive(data);
						$('#change-password-errors').html(data.msg).show();
						$('input[type="submit"]').prop('disabled', false);
					} else {
						$('#change-password-success').html(data.msg).show();
						setTimeout(
							function(){
								$('input[type="submit"]').prop('disabled', false);
								$('#change-password')[0].reset();

								if ($('.password-strength-meter-score').is('[class*="psms"]')) {
									$('.password-strength-meter-score').removeClass('psms-25 psms-50 psms-75 psms-100');
								}

								if ($("#pswmeter")[0]) {
									// div exists
									const myPassMeter = passwordStrengthMeter({
										containerElement: '#pswmeter',
										passwordInput: '#password',
										showMessage: true,
										messageContainer: '#pswmeter-message',
										borderRadius: 2,
										messagesList: pswmessages
									});
								}
							},
							1000);
					}
				}
			});
		}
		// Forgotten password reminder form
		if(form.attr('name') == 'password-reminder') {
			$.ajax({
				url: '/applicant/passwordReminder',
				type: 'POST',
				dataType: 'json',
				data: $('#password-reminder').serialize(),

				beforeSend: function() {
					$('#reminder-errors').hide();
					$('#reminder-success').hide();
					$('input[type="submit"]').prop('disabled', true);
				},

				success: function(data) {
					if(data.error == true) {
						logoutInactive(data);
						$('#reminder-errors').html(data.msg).show();
					} else {
						$('#reminder-success').html(data.msg).show();
					}
					$('input[type="submit"]').prop('disabled', false);
				}
			});
		}
		// Add new/Edit current vehicle form
		if(form.attr('name') == 'add-vehicle') {
			let vehicleId = $('[data-vehicleId]').val();
			if (vehicleId.length) {
				vehicleId = '/' + vehicleId;
			}
			$.ajax({
				url: '/applicant/addVehicle' + vehicleId,
				type: 'POST',
				dataType: 'json',
				data: $('#add-vehicle').serialize(),

				beforeSend: function() {
					$('#errors').hide();
					$('#success').hide();
					$('input[type="submit"]').prop('disabled', true);
				},

				success: function(data) {
					if(data.error == true) {
						logoutInactive(data);
						$('#errors').html(data.msg).show();
						$('input[type="submit"]').prop('disabled', false);
					} else {
						$('#success').html(data.msg).show();
						window.location.href = '/app/my-vehicles';
					}
				}
			});
		}

		if(form.attr('name') == 'scratchcard-form') {
			$.ajax({
				url: '/applicant/scratchcardPurchase',
				type: 'POST',
				dataType: 'json',
				data: $('#scratchcard-form').serialize(),

				success: function(data, textStatus, xhr) {
					//called when successful
					if(data.error==true)
					{
						logoutInactive(data);
						$('#errors').html(data.errorMsg).show();
					} else {
						window.location='/app/order-summary/sc';
					}
				},
				error: function(xhr, textStatus, errorThrown) {
					//called when there is an error
					$('#errors').html('Error: '+errorThrown).show();
				}
			});
		}

		if ((form.attr('name') == 'summaryForm') && !isScratchcard) {
			summaryFormSubmit();
		}

		if ((form.attr('name') == 'scratchcardSummaryForm') && isScratchcard) {
			scratchcardSummaryFormSubmit();
		}

		if (form.attr('name') == 'applicationForm') {
			$('#proceed-to-approval-button').prop('disabled', true);

			let cartArr = [];
			let $form = $('#applicationForm');
			let formData = new FormData(document.getElementById('applicationForm'));
			let cart  = {};

			let permitType = $form.find('input[name="permitType"]').val();

			// cart[`${permitType}Id`] = $form.find('input[name="bookingId"]').val();
			cart.permitId = $form.find('input[name="bookingId"]').val();
			cart.locationId = $form.find('input[name="locationId"]').val();
			cart.locationType = $form.find('input[name="locationType"]').val();
			cart.application = {};

			for (var entry of formData.entries()) {
				if (!cart.application.hasOwnProperty(entry[0])) {
					cart.application[entry[0]] = entry[1];
				} else {
					var entryArr = [];

					// If its already an array loop it and push elements
					if (Array.isArray(cart.application[entry[0]])) {
						for (var subEntry of cart.application[entry[0]]) {
							entryArr.push(subEntry);
						}
					} else {
						entryArr.push(cart.application[entry[0]]);
					}

					entryArr.push(entry[1]);
					cart.application[entry[0]] = entryArr;
				}
			}

			// Add any missing checkboxes because they weren't checked
			$("#applicationForm input:checkbox").each(function() {
				if (!cart.application.hasOwnProperty(this.name)) {
					cart.application[this.name] = '';
				}
			});

			cartArr.push(cart);
			formData.append('cart', JSON.stringify(cartArr));
			formData.append('type', permitType);
			formData.append('purpose', 'Renewal');

			$.when(
				submitQuestionnaire(formData)
			).done(function(data) {
				if (data == 'logout') {
					logoutInactive({ logout:true });
					return;
				}

				let $errors = $('#questionnaire-errors');

				if (data.error) {
					// Show error messages
					$errors.find('[data-target="error-content"]').html(data.msg);
					$errors.removeClass('hide');
					$errors.show();

					// Add error labels
					$.each(data['error-list'], function(ele, msg) {
						$('body').find(`[data-target="${ele}-error-label"]`).addClass('is-invalid-label');
					});

					$('html, body').animate({
						scrollTop: $('#questionnaire-errors').offset().top
					}, 800);

					return;
				}

				$errors.addClass('hide');

				if (isScratchcard) {
					scratchcardSummaryFormSubmit();
				} else {
					summaryFormSubmit();
				}
			}).fail(function(xhr, textStatus, errorThrown) {
				console.warn(errorThrown);
			});
		}

		if(form.attr('name') == 'close-account') {
			let $button = form.find('input[type="submit"]');
			$.ajax({
				url: form.attr('action'),
				type: 'POST',
				dataType: 'json',
				data: form.serialize(),

				beforeSend: function() {
					$button.prop('disabled', true);
					$('#close-account-errors').hide();
					$('#close-account-success').hide();
				},

				success: function(data) {
					if(data.error == true) {
						logoutInactive(data);

						$('#close-account-errors').html(data.errorMsg).fadeIn('slow');
					} else {

						setTimeout(function () {
							window.location.href = '/';
						}, 800);

					}
				},
				complete: function() {
					$button.prop('disabled', false);
				}
			});
		}
		if(form.attr('name') == 'use-scratchcard-form') {
			let $this = $('#use-scratchcard-submit');
			var scratchcardId = $('#scratchcard-id').val();

			// reset error
			$('#use-scratchcard-errors').html('').hide();
			$('#scratchcard-success').html('').hide();

			// Combine startDate and startTime
			let date = $('#use-scratchcard-form input[name="startDate"]').val();
			let time = $('#use-scratchcard-form input[name="startTime"]').val();
			let combinedDateTime = date + ' ' + time;

			// Serialize the form data into an array of objects
			let form = $('#use-scratchcard-form').serializeArray();
			let inputChoiceValue;

			// Loop through the form data to find inputChoice and get its value
			for (let i = 0; i < form.length; i++) {
				if (form[i].name === 'inputChoice') {
					inputChoiceValue = form[i].value;
					break;
				}
			}
			// Modify the form data based on the inputChoice value
			if (inputChoiceValue === 'manual' || inputChoiceValue === 'account') {
				form = form.filter(item => item.name !== 'scVrm');
				// Add a new entry for scVrm from the input field
				if (inputChoiceValue === 'manual') {
					form.push({ name: 'scVrm', value: $('input[name="scVrm"]').val() });
				} else if (inputChoiceValue === 'account') {
					form.push({ name: 'scVrm', value: $('select[name="scVrm"]').val() });
				}
			}
			// Add a new object for StartDateTime to the array
			form.push({ name: 'startDateTime', value: combinedDateTime });

			$.ajax({
				url: '/applicant/activateScratchcard/'+scratchcardId,
				type: 'POST',
				dataType: 'json',
				data: form,
				beforeSend: function() {
					$this.prop('disabled', true);
				},
				success: function(data) {
					if (data.error==true) {
						logoutInactive(data);
						$('#use-scratchcard-errors').html(data.errorMsg).fadeIn();
						$this.prop('disabled', false);
					} else {
						let adaptive = $('#my-bookings-adaptive').length > 0;
						// reloadScratchcardList(adaptive);
						// reloadPermitsList(adaptive);
						$(`#scratchcard-${scratchcardId}`).replaceWith(data.scratchcard);
						$('#bookings').append(data.permit);
						$('body').trigger('redraw.bookings')
						$('#reveal-use-scratchcard').foundation('close');
						// empty out fields from previous use
						$('input[name="startDate"]').val('');
						$('input[name="startTime"]').val('');
						$('input[name="scVrm"]').val('');
						$('input[name="creditNo"]').val(1);

						$('#use-scratchcard-form').trigger('reset');
						$this.prop('disabled', false);
						if ($('select[name="vehicleType"]').length) {
							$('select[name="vehicleType"]')[0].sumo.reload();
							$('select[name="vehicleMake"]')[0].sumo.reload();
							$('select[name="vehicleColour"]')[0].sumo.reload();
						}
						flatpickrReinit();
						$('#scratchcard-success').html(data.errorMsg).fadeIn();
						if (('#view-booking').length > 0) {
							window.location.reload();
						}
					}
				},
				error: function(obj, textStatus, serverErr) {
					$this.prop('disabled', false);
					$('#use-scratchcard-errors').html('Error: ' + serverErr).fadeIn();
				}
			});
		}
	});

function getDefaultReminder() {
	const permit = !$('#location-scratchcard-detail').length;
	var data;

	if (!!$('#reminderTime').data('changed')) {
		return;
	}

	if (permit) {
		let tariffId = $('#permit-details-proceed-button').attr('data-id');
		let emissionDurationId = $('select[name="emissionsDuration"]').val();
		let durationId = $('select[name="tariffDuration"]').val();
		data = { tariffId, emissionDurationId, durationId };
	} else {
		let tariffId = $('#scratchcard-details-proceed-button').attr('data-id');
		data = { tariffId };
	}

	$.ajax({
		url: '/applicant/getDefaultReminder/',
		type: 'POST',
		dataType: 'json',
		data: data,
		success: function(data) {
			$('#reminderTime').val(data.time);
			if (data.interval) {
				$('#reminderType')[0].sumo.selectItem(data.interval);
			} else {
				$('#reminderType')[0].sumo.selectItem('');
			}
			$('#reminderType').trigger('change', ['manual']);
			if (data.tariffs) {
				$('#tariff-reminders').find('p').text(data.tariffs);
				$('#additional-reminder').text(data.count ? i18n.__('labels.additional') : '');
			}
		}
	});
}

function vehicleSummaryTemplate(vehicle) {
	let vehicleDetails = '';
	vehicleDetails += '<h5 id="vehicleSummaryHeader" data-vehicle="' + vehicle.id + '" class="h6 border-bottom">'
		+ i18n.__('portal.vehicles.new.details') + '</h5>'
		+ '<ul class="no-bullet">'
		+ '<li>' + i18n.__('labels.type') + ': <strong><i class="far '+vehicle.type.icon+'"></i>&nbsp;' + vehicle.type.name + '</strong></li>'
		+ '<li><abbr title="' + i18n.__('labels.vrm.abbr') + 'Vehicle Registration Mark">' + i18n.__('labels.vrm') + '</abbr>: <strong>' + vehicle.vrm + '</strong></li>';

	if (vehicle.name) {
		vehicleDetails += '<li>' + vehicle.name.title + ': <strong>' + vehicle.name.value + '</strong></li>';
	}

	if (vehicle.make) {
		vehicleDetails += '<li>' + i18n.__('labels.make') + ': <strong>' + vehicle.make + '</strong></li>';
	}

	if (vehicle.model) {
		vehicleDetails += '<li>' + i18n.__('labels.model') + ': <strong>' + vehicle.model + '</strong></li>';
	}

	if (vehicle.colour) {
		vehicleDetails += '<li>' + i18n.__('labels.colour') + ': <strong>' + vehicle.colour + '</strong></li>';
	}

	vehicleDetails += '</ul>';

	return vehicleDetails;
}

function loadMap() {
	let locationType = $('body').find('input[name="locationType"]').eq(0).val();
	let locationId = $('body').find('input[name="locationId"]').eq(0).val();
	if (locationId && locationType) {
		createGoogleMap(locationId, locationType);
	}
}

function getMultiVehicleDropdowns(bookingId, bookingTable) {
	return $.ajax({
		url: '/applicant/multiVehicleChangeDropdowns',
		type: 'POST',
		dataType: 'json',
		data: {
			bookingId,
			bookingTable
		},
		success: function(data) {
			if(data.error == true) {
				logoutInactive(data);
			}
			$('#assigned-vehicles').html(data.assignedVehicles);
			$('#available-vehicles').html(data.availableVehicles);
		}
	});
}

function showPassword ($checkbox) {
	let $target = $('#'+$checkbox.data('toggle'));
	let toggleType = $target.attr('type') === 'password' ? 'text' : 'password';
	$target.attr('type', toggleType);
}

function initializeFilter(target) {
	// Retrieve last used filters from localStorage
	const activeFilterKey = target + '-active-filter';
	const inactiveFilterKey = target + '-inactive-filter';

	const activeFilter = localStorage.getItem(activeFilterKey) === 'true';
	const inactiveFilter = localStorage.getItem(inactiveFilterKey) === 'true';

	$(`#${target}-active-filter`).prop('checked', activeFilter);
	$(`#${target}-inactive-filter`).prop('checked', inactiveFilter);

	// Initial filter application
	filterPermits(activeFilter, inactiveFilter);

	// Add event listeners to update localStorage and apply filtering logic on checkbox change
	$('body').on('change', `#${target}-active-filter, #${target}-inactive-filter`, function () {
		const newActiveFilter = $(`#${target}-active-filter`).prop('checked');
		const newInactiveFilter = $(`#${target}-inactive-filter`).prop('checked');

		localStorage.setItem(activeFilterKey, newActiveFilter);
		localStorage.setItem(inactiveFilterKey, newInactiveFilter);

		// Apply filtering logic directly with checkbox states
		filterPermits(newActiveFilter, newInactiveFilter);
	});
}

// Function to apply filtering logic based on checkbox states
function filterPermits(activeFilter, inactiveFilter) {
	const permits = $('.permit-item');
	permits.each(function () {
		const isActive = $(this).hasClass('active');
		const show = (activeFilter && isActive) || (inactiveFilter && !isActive) || (!activeFilter && !inactiveFilter);
		$(this).toggle(show);
	});
}

$(document).ready(function (){

	$('#primary-menu-account li ul li a').on('touchend', function() {
		window.location.href = $(this).attr('href');
	});

	if ($('[data-confirm-password-checkbox]').length) {
		$('[data-confirm-password-checkbox]').on('change', function() {
			showPassword($(this));
		});
	}

	$('input[name="createAccount"]').on('click', function () {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	});

	var url = $(location).attr('href'),
	parts = url.split("/");

	if (parts[4] == 'create-account') {
		if (usingEmailRestriction) {
			accountType['value'] = 1;
		} else {
			$('#account-type-select').foundation('open');
			$('input[name="createAccount"]').prop('disabled', true);
		}
	}

	var checkUrl = new URL(window.location.href);
	var params = new URLSearchParams(checkUrl.search);
	if (params.get('incomplete') === '1') {
		$('#account-type-select').foundation('open');
	}

	if (typeof Dropzone != 'undefined') {

		// Check if we're on the import page
		if (parts[4] == 'import-vehicles') {
			createImportDropzone();
		}
	}

	$('#account-type-select-cancel, #account-type-select-login-cancel').on('click', function () {
		window.location = '/app/logout';
	});

	$('#account-type-send').on('click', function () {
		accountType['value'] = $('input[name="accountType"]:checked').val();

		if (accountType['value'] != undefined) {
			$('#account-type-select').foundation('close');
			$('input[name="createAccount"]').prop('disabled', false);
		} else {
			$('#account-type-select-error').show();
		}
	});

	$('body').on('change', 'input[name="accountType"]', function () {
		if ($('input[name="accountType"]:checked').length == 0) {
			return;
		}
		$('input[name="residential"]').prop('disabled', $(this).val() == 2);
	});

	$('#account-type-login-send').on('click', function () {
		var updatedType = $('input[name="accountType"]:checked').val();

		if(updatedType != undefined) {

			$.ajax({
				url: '/applicant/updateAccountType',
				type: 'POST',
				dataType: 'json',
				data: { 'accountType' : updatedType }
			});

			$('#accountTypeSet').foundation('close');
			window.location.reload();
		} else {
			$('#account-type-select-login-error').show();
		}
	});

	$('#account-type-details-send').on('click', function () {
		var updatedType = $('input[name="accountType"]:checked').val();

		if(updatedType != undefined) {
			$.ajax({
				url: '/applicant/updateAccountType',
				type: 'POST',
				dataType: 'json',
				data: { accountType : updatedType }
			});
			$('#account-type-select').foundation('close');
		} else {
			$('#account-type-select-login-error').show();
		}
	});

	/* ------------------------ */
	/* Window resize Mobile Map */
	/* ------------------------ */

	$(window).bind('resize', function () {
		if ($(this).width() < 639) {
			$('#reveal-map-canvas').removeClass('width-25 width-50 width-75 width-100').addClass('map__mobile--full');
		}
	}).trigger('resize');

	$('body').on('click', '[data-open="reveal-change-permit-vehicle-multi"]', function() {
		var bookingId = $(this).attr('rel');
		var bookingTable = $(this).attr('data-table');
		$('#limit-warning-multi').removeClass('warning alert').hide();
		$('#reveal-change-permit-vehicle-multi-errors').hide();
		getMultiVehicleDropdowns(bookingId, bookingTable)
		.then(() => {
			$('button[data-trigger="change-multi-vehicle-confirm"]').prop('disabled', false);
			if (!isMobile) {
				let config = {
					renderLi: (li, originalOption) => li.attr('data-id', originalOption.parent().attr('name')+'-'+originalOption.val())
				};
				$('#reveal-change-permit-vehicle-multi').find('select').SumoSelect(mergeSumoConfig(config));
			}
		});

		// Get vehicle limit
		$.ajax({
			url: '/applicant/checkPermitVehicleLimits',
			type: 'POST',
			dataType: 'json',
			data: {
				bookingId: bookingId,
				bookingTable: bookingTable,
				action: 'change',
			},
			success: function(data) {
				if (data.error == true) {
					logoutInactive(data);
				} else {

					const updateDetails = vehicleUpdateModal(
						'change',
						data.tariffLimit,
						data.availableChanges,
						data.vehicleChangeApproval,
						data.pendingVehicleChangeAction // data.pendingVehicleChange
					);

					if (updateDetails.error) {
						$('button[data-trigger="change-multi-vehicle-confirm"]').prop('disabled', true);
						$('#reveal-change-permit-vehicle-multi').find('select[name="vehicle"]').prop('disabled', true);
						$('#reveal-change-permit-vehicle-multi').find('select[name="assignedVehicle"]').prop('disabled', true);
					}

					if (updateDetails.message) {
						$('#limit-warning-multi').html(updateDetails.message).addClass(updateDetails.classList).show();
					}

					let confirmText = i18n.__(data.questionnaire ? 'buttons.next' : 'buttons.confirm');
					$('#reveal-change-permit-vehicle-multi').find('[data-questionnaire]').attr('data-questionnaire', data.questionnaire);
					$('#reveal-change-permit-vehicle-multi').find('[data-questionnaire]').html(confirmText);

					$('#vehicle-change-questionnaire-reveal').find('input[name="locationId"]').val(data.locationId);
					$('#vehicle-change-questionnaire-reveal').find('input[name="locationType"]').val(data.locationType);
				}
			},
			error: function(obj, textStatus, serverErr) {
				$('button[data-trigger="change-multi-vehicle-confirm"]').prop('disabled', true);
				$('#reveal-change-permit-vehicle-multi select[name="vehicle"], select[name="assignedVehicle"]').prop('disabled', false);
				$('#reveal-change-permit-vehicle-multi-errors').html('Error: ' + serverErr).fadeIn();
			}
		});

		$('#reveal-change-permit-vehicle-multi').data('booking-id', bookingId).data('booking-table', bookingTable);
	});

	$('[data-trigger="change-multi-vehicle-confirm"]').on('click', function(e) {
		e.preventDefault();

		let $this = $(this);
		let oldVehicle = $('select[name="assignedVehicle"]').val();
		let newVehicle = $('#change-multi-permit-vehicle select[name="vehicle"]').val();
		let bookingId = $('#reveal-change-permit-vehicle-multi').data('booking-id');
		let bookingTable = $('#reveal-change-permit-vehicle-multi').data('booking-table');
		let questionnaire = $this.attr('data-questionnaire');
		let $reveal = $('#vehicle-change-questionnaire-reveal');

		// reset error
		$('#reveal-change-permit-vehicle-multi-errors').html('').hide();

		if (questionnaire) {
			$.when(
				validateVehicleChange('change', newVehicle, bookingId, bookingTable, oldVehicle)
			).then(function(data) {
				if (data.error) {
					$('#change-permit-vehicle-errors').html(data.errorMsg).fadeIn();
					return;
				}

				return vehicleChangeQuestionnaire(bookingId, bookingTable, 'change');
			}).done(function(questionnaire) {
				if (questionnaire == 'error') {
					return;
				}

				if (questionnaire == 'logout') {
					logoutInactive({ logout:true });
				}

				if (!questionnaire) {
					changeMultiVehicle($this, oldVehicle, newVehicle, bookingId, bookingTable);
					return;
				}

				$('#reveal-change-permit-vehicle').foundation('close');
				$reveal.find('[data-target="vehicle-change-questionnaire-container"]').html(questionnaire);
				$reveal.find('input[name="bookingId"]').val(bookingId);
				$reveal.find('input[name="bookingTable"]').val(bookingTable);
				$reveal.find('input[name="oldVehicle"]').val(oldVehicle);
				$reveal.find('input[name="newVehicle"]').val(newVehicle);

				initialiseQuestionnaire();

				$reveal.foundation('open');
			});

			return;
		}

		changeMultiVehicle($this, oldVehicle, newVehicle, bookingId, bookingTable);
	});

	$('body').on('click', '[data-open="reveal-add-permit-vehicle-multi"]', function() {
		var bookingId = $(this).attr('rel');
		var bookingTable = $(this).attr('data-table');

		$('#limit-warning-add').html('').hide();
		$('#add-unassigned').html('');
		$('#reveal-add-permit-vehicle-multi').find('[data-new-vehicle-multi]').html('').hide();

		$.when(
			getUnassignedVehiclesForMulti(bookingId, bookingTable)
		).then(function(html) {
			if (html.error == true) {
				logoutInactive(html);
			}

			$('#add-unassigned').html(html);

			$.ajax({
				url: '/applicant/checkPermitVehicleLimits',
				type: 'POST',
				dataType: 'json',
				data: {
					bookingId: bookingId,
					bookingTable : bookingTable,
					action: 'addition'
				},
				success: function(data) {
					if (data.error == true) {
						logoutInactive(data);
					} else {
						const updateDetails = vehicleUpdateModal(
							'add',
							data.additionLimit,
							data.availableAdditions,
							data.vehicleAdditionApproval,
							data.pendingVehicleChangeAction // data.pendingVehicleAddition
						);

						if (updateDetails.error) {
							$('button[data-trigger="add-multi-vehicle-confirm"]').prop('disabled', true);
							$('#reveal-add-permit-vehicle-multi select[name="vehicle"]').prop('disabled', true);
						} else if (!isMobile) {
							let config = {
								renderLi: (li, originalOption) => li.attr('data-id', originalOption.parent().attr('name')+'-'+originalOption.val())
							};
							$('#reveal-add-permit-vehicle-multi select[name="vehicle"]').SumoSelect(mergeSumoConfig(config));
						}
						if (updateDetails.message) {
							$('#limit-warning-add').html(updateDetails.message).addClass(updateDetails.classList).show();
						}

						let confirmText = i18n.__(data.questionnaire ? 'buttons.next' : 'buttons.confirm');
						$('#reveal-add-permit-vehicle-multi').find('[data-questionnaire]').attr('data-questionnaire', data.questionnaire);
						$('#reveal-add-permit-vehicle-multi').find('[data-questionnaire]').html(confirmText);
						$('#vehicle-change-questionnaire-reveal').find('input[name="locationId"]').val(data.locationId);
						$('#vehicle-change-questionnaire-reveal').find('input[name="locationType"]').val(data.locationType);
					}
				}
			});
		}).done(function() {
			$('#reveal-add-permit-vehicle-multi select[name="vehicle"]').trigger('change');
		}).fail(function(xhr, textStatus, errorThrown) {
			console.warn(errorThrown);
		});
	});

	$('body').on('click tap', '[data-trigger="select-tariff"]', function(event) {
		event.preventDefault();

		let $parent = $(this).parents('[data-target="tariff-option"]');
		let id = $(this).attr('data-id');
		let description = $(this).parents('[data-target="tariff-option"]').find('.text-truncate').html();
		let type = $parent.attr('data-type');
		let name = $parent.attr('data-name');
		let price = $parent.attr('data-price');
		let sms = $parent.attr('data-sms');
		let offsetText = $parent.attr('data-offsetText');
		let isEmissions = $parent.attr('data-isEmissions');
		let emissionsTariffId = $parent.attr('data-emissionsId');
		let renewable = $parent.attr('data-renewable');
		let maxPurchaseable = $parent.attr('data-maxPurchased');

		let locationType = $parent.attr('data-location-type');
		let locationId = $parent.attr('data-location-id');

		let currentType = $('input[name="locationType"]').val();
		let currentId = $('input[name="locationId"]').val();

		// If these have a value we're on a category page
		if (locationId && locationType && ((locationId != currentId) || (locationType != currentType))) {

			// Set the location id and type dynamically from this tariff
			$('input[name="locationType"]').val(locationType);
			$('input[name="locationId"]').val(locationId);

			// Update the terms for the true location
			$.when(getTacs()).done((terms) => { $('#terms').html(terms); });
		}

		if ($('[data-target="offsetText"]').hasClass('hide')) {
			$('[data-target="offsetText"]').removeClass('hide');
		}

		$('#location-tariff-questionnaire [data-target="tariff-name-title"]').html(name);

		// Hide any previous questionnaire errors
		$('#questionnaire-errors').hide();

		sms = sms == '' ? 0 : sms;

		if (type == 'permit') {
			showPermit(id, description, name, price, sms, offsetText, renewable, isEmissions, emissionsTariffId, maxPurchaseable);
		} else {
			showScratchcard(id, description, name, price, maxPurchaseable);
		}
	});

	$('#permit-details-proceed-button').on('click tap', function(event) {
		event.preventDefault();

		let id = $(this).attr('data-id');
		let $reveal = $('#location-tariff-questionnaire');
		let siteCurrencySymbol = '';
		let permitType = $(this).attr('data-type');
		let noDates = 0;

		if ($('input[name="noDate"]').length && $('input[name="noDate"]').val() === '1') {
			noDates = 1;
			let now = new Date();
			now.setMinutes(now.getMinutes() + 1);
			let validFromDate = now.toISOString().slice(0, 10);
			let validFromTime = now.toTimeString().slice(0, 8);
			$('#permit-form').append(`<input type="hidden" name="validFromDate" value="${validFromDate}">`);
			$('#permit-form').append(`<input type="hidden" name="validFromTime" value="${validFromTime}">`);
		}

		if (!$('input[name="validFromDate"]').val()) {
			$('#permit-errors').find('.callout').html(i18n.__('portal.purchase.errors.start.date'));
			$('#permit-errors').show();
			return;
		}

		$.when(
			setupPermitCart(id)
		).then(function() {
			return $.ajax({
				url: '/app/calculate-permit',
				type: 'POST',
				dataType: 'json',
				data: $('#permit-form').serialize(),
			});
		}).then(function(data) {
			if (data.error) {
				return;
			}
			siteCurrencySymbol = data.currencySymbol;
			$.each(cart, function (index) {
				if (singlePrices) {
					cart[index].price = parseFloat(singlePrices[cart[index].options.vehicles]);
					let smsReminder = $('#sms-reminder').is(':checked');
					if (smsReminder) {
						cart[index].price += parseFloat(data.smsFee);
					}
				} else {
					cart[index].price = data.total;
				}
				cart[index].options.validFromTime = data.validFromTime;
				cart[index].options.smsFee = data.smsFee;
				cart[index].options.expiresOn = data.expiresOn;
				cart[index].options.expiresOnClean = data.expiresOnClean;
				cart[index].options.currency = data.currency;
				if (noDates == 1) {
					cart[index].noDates = 1;
				}
			});
		}).then(function() {
			return validatePermitForm(siteCurrencySymbol);
		}).then(function(data) {
			let $errors = $('#permit-errors');

			if (data.error) {
				logoutInactive(data);
				if (data.msg) {
					$errors.find('.callout').html(data.msg);
					$errors.show();
				}
				return 'error';
			}

			$errors.html('').hide();

			return getQuestionnaire(cart, permitType);
		}).done(function(questionnaire) {
			if (questionnaire == 'error') {
				return;
			}

			if (questionnaire == 'logout') {
				logoutInactive({ logout:true });
			}

			if (!questionnaire) {
				loadOrderSummary('permit');
				return;
			}

			let isModal = $('#location-permit-detail').hasClass('reveal');

			if (isModal) {
				$('#location-permit-detail').foundation('close');
			}

			$reveal.find('[data-target="questionnaire-container"]').html(questionnaire);
			$reveal.find('[data-trigger="questionnaire-submit"]').attr('data-type', permitType);

			initialiseQuestionnaire();

			if (isModal) {
				$reveal.foundation('open');
			} else {
				$('#purchase').empty().append($reveal.removeClass('hide'));
			}

		}).fail(function(xhr, textStatus, errorThrown) {
			console.warn(errorThrown);
		});
	});

	$('#scratchcard-details-proceed-button').on('click tap', function(event) {
		event.preventDefault();

		let id = $(this).attr('data-id');
		let $reveal = $('#location-tariff-questionnaire');

		setupScratchcardCart(id);

		$.when(
			validateScratchcardForm(cart)
		).then(function(data) {
			let $errors = $('#scratchcard-errors');

			if (data.error) {
				$errors.find('.callout').html(data.msg);
				$errors.show();
				return 'error';
			}

			$errors.html('').hide();

			return getQuestionnaire(cart, 'scratchcard');
		}).done(function(questionnaire) {
			if (questionnaire == 'error') {
				return;
			}

			if (questionnaire == 'logout') {
				logoutInactive({ logout:true });
			}

			if (!questionnaire) {
				loadOrderSummary('scratchcard');
				return;
			}

			let isModal = $('#location-scratchcard-detail').hasClass('reveal');

			if (isModal) {
				$('#location-scratchcard-detail').foundation('close');
			}

			$reveal.find('[data-target="questionnaire-container"]').html(questionnaire);
			$reveal.find('[data-trigger="questionnaire-submit"]').attr('data-type', 'scratchcard');

			initialiseQuestionnaire();

			if (isModal) {
				$reveal.foundation('open');
			} else {
				$('#purchase').empty().append($reveal.removeClass('hide'));
			}

		}).fail(function(xhr, textStatus, errorThrown) {
			console.warn(errorThrown);
		});
	});

	$('body').on('click tap', '[data-trigger="questionnaire-submit"]', function(event) {
		event.preventDefault();

		let button = $(this);
		button.prop('disabled', true).html(i18n.__('labels.please.wait'));

		let type = $(this).attr('data-type');
		let formData = new FormData(document.getElementById('questionnaire-form'));

		$.each(cart, function (index) {
			cart[index].application = {};

			for (var entry of formData.entries()) {
				if (!cart[index].application.hasOwnProperty(entry[0])) {
					cart[index].application[entry[0]] = entry[1];
				} else {
					var entryArr = [];

					// If its already an array loop it and push elements
					if (Array.isArray(cart[index].application[entry[0]])) {
						for (var subEntry of cart[index].application[entry[0]]) {
							entryArr.push(subEntry);
						}
					} else {
						entryArr.push(cart[index].application[entry[0]]);
					}

					entryArr.push(entry[1]);
					cart[index].application[entry[0]] = entryArr;
				}
			}
			// Add any missing checkboxes because they weren't checked
			$("#questionnaire-form input:checkbox").each(function() {
				if (!cart[index].application.hasOwnProperty(this.name)) {
					cart[index].application[this.name] = '';
				}
			});
		});

		formData.append('cart', JSON.stringify(cart));
		formData.append('type', type);

		$.when(
			submitQuestionnaire(formData)
		).done(function(data) {

			if (data == 'logout') {
				logoutInactive({ logout:true });
				return;
			}

			let $errors = $('#questionnaire-errors');

			if (data.error) {
				// Show error messages
				$errors.find('.callout').html(data.msg);
				$errors.show();

				// Add error labels
				$.each(data['error-list'], function(ele, msg) {
					$('body').find(`[data-target="${ele}-error-label"]`).addClass('is-invalid-label');
				});
				button.prop('disabled', false).html(i18n.__('buttons.proceed'));
				return;
			}

			$.each(cart, function (index) {
				cart[index].permitId = data[index].permitId;
				cart[index].scratchcardId = data[index].scratchcardId;
				cart[index].applicationId = data[index].applicationId;
			});

			$errors.html('').hide();

			loadOrderSummary(type);
		}).fail(function(xhr, textStatus, errorThrown) {
			console.warn(errorThrown);
			button.prop('disabled', false).html(i18n.__('buttons.proceed'));
		});
	});

	// TODO: Refactor this
	$('body').on('click tap', '[data-trigger="vehicle-change-questionnaire-submit"]', function(event) {
		event.preventDefault();

		let button = $(this);
		button.prop('disabled', true);
		let cartArr = [];
		let $form = $('#vehicle-change-questionnaire-form');
		let formData = new FormData(document.getElementById('vehicle-change-questionnaire-form'));
		let cart  = {};

		cart.permitId = $form.find('input[name="bookingId"]').val();
		cart.bookingTable = $form.find('input[name="bookingTable"]').val();
		cart.locationId = $form.find('input[name="locationId"]').val();
		cart.locationType = $form.find('input[name="locationType"]').val();
		cart.application = {};

		for (var entry of formData.entries()) {
			if (!cart.application.hasOwnProperty(entry[0])) {
				cart.application[entry[0]] = entry[1];
			} else {
				var entryArr = [];

				// If its already an array loop it and push elements
				if (Array.isArray(cart.application[entry[0]])) {
					for (var subEntry of cart.application[entry[0]]) {
						entryArr.push(subEntry);
					}
				} else {
					entryArr.push(cart.application[entry[0]]);
				}

				entryArr.push(entry[1]);
				cart.application[entry[0]] = entryArr;
			}
		}

		// Add any missing checkboxes because they weren't checked
		$("#vehicle-change-questionnaire-form input:checkbox").each(function() {
			if (!cart.application.hasOwnProperty(this.name)) {
				cart.application[this.name] = '';
			}
		});
		cartArr.push(cart);
		formData.append('cart', JSON.stringify(cartArr));
		formData.append('type', 'permit');
		//formData.append('purpose', 'Vehicle Change');

		$.when(
			submitQuestionnaire(formData)
		).done(function(data) {
			if (data == 'logout') {
				logoutInactive({ logout:true });
				return;
			}

			let $errors = $('#vehicle-change-questionnaire-errors');

			if (data.error) {
				// Show error messages
				$errors.find('.callout').html(data.msg);
				$errors.show();

				// Add error labels
				$.each(data['error-list'], function(ele, msg) {
					$('body').find(`[data-target="${ele}-error-label"]`).addClass('is-invalid-label');
				});
				button.prop('disabled', false);
				return;
			}

			if ($form.find('input[name="purpose"]').val() == 'Vehicle Addition') {
				addVehicle(
					$('[data-trigger="add-multi-vehicle-confirm"]'),
					$form.find('input[name="vehicle"]').val(),
					$form.find('input[name="bookingId"]').val(),
					$form.find('input[name="bookingTable"]').val(),
					true
				);
			} else if ($form.find('input[name="vehicle"]').val()) {
				changeVehicle(
					$('[data-trigger="change-vehicle-confirm"]'),
					$form.find('input[name="newVehicle"]').val(),
					$form.find('input[name="bookingId"]').val(),
					$form.find('input[name="bookingTable"]').val(),
					true
				);
			} else {
				changeMultiVehicle(
					$('[data-trigger="change-multi-vehicle-confirm"]'),
					$form.find('input[name="oldVehicle"]').val(),
					$form.find('input[name="newVehicle"]').val(),
					$form.find('input[name="bookingId"]').val(),
					$form.find('input[name="bookingTable"]').val(),
					true
				);
			}
		}).fail(function(xhr, textStatus, errorThrown) {
			button.prop('disabled', false);
			console.warn(errorThrown);
		});
	});

	// When a permit or scratchcard modal is opened default the T&Cs and hide
	// any previous errors also reset the reminder fields
	$('#location-permit-detail, #location-scratchcard-detail').on('open.zf.reveal', function() {
		$(this).find('input[name="tAndC"]').prop('checked', false);
		$(this).find('[data-abide-error]').hide();
		$(this).find('.form-error').removeClass('is-visible').hide();
		$(this).find('[data-target="permit-reminder-time"]').hide();
		$('#sms-reminder').prop('checked', false);
		$('#email-reminder').prop('checked', false);
		$('#reminderTime').val(1);
		$('#reminderType').val('hour');
		$('#reminderType')[0].sumo.reload();
	});

	$('#location-permit-detail').on('change', 'select[name="vehicle[]"], select[name="emissionsDuration"]', function (e) {
		if (!$('body').find('[data-target="emissions-duration-dropdown"]').is(':visible')) {
			return;
		}

		if (!$('body').find('select[name="emissionsDuration"]').length) {
			return;
		}

		let $reveal = $('#location-permit-detail');

		let vehicles = [];

		$('select[name="vehicle[]"]').each(function() {
			if ($(this).val()) {
				vehicles.push($(this).val());
			}
		});

		let duration = $reveal.find('select[name="emissionsDuration"]').val();
		let permitPeriod = $('#permit-details-proceed-button').attr('data-id');

		if (duration == '' || !vehicles.length) {
			initialPrice = i18n.__('variable');
			$reveal.find('input[name="permitPrice"]').val(initialPrice);
			$reveal.find('[data-target="permit-price"]').html(initialPrice);
			return;
		}

		let emissionsDurationSet = (e.target.name === 'emissionsDuration') ? true : false;

		calculateEmissionsCost(vehicles, duration, permitPeriod);
		calculatePermit($('#permit-valid-from-date').val(), emissionsDurationSet);
		getDefaultReminder();
	});

	$('#location-permit-detail').on('change', 'select[name="vehicle[]"]', function (e) {
		let vehicleId = $(this).val();

		if ($('body').find('input[name="emissionsTariffId"]').length) {
			let emissionId = $('body').find('input[name="emissionsTariffId"]').val();
			$.when (
				getEmissionsTariffDurationDropdown(emissionId, vehicleId)
			).done(function(html) {
				$('body').find('[data-target="emissions-duration-dropdown"]').html(html);

				if (!(/Mobi|Android/i.test(navigator.userAgent)) && !iOSiPadOS) {
					$('body').find('select[name="emissionsDuration"]').SumoSelect(sumoConfig);
				}
			});
		}
	});

	$('#location-permit-detail').on('change', 'select[name="tariffDuration"]', function (e) {
		calculatePermit($('#permit-valid-from-date').val());
		getDefaultReminder();
	});

	function redrawMyDocumentsTable(html)
	{
		$('#my-documents-table')
			.html(html)
			.find('.dropdown-pane').each(function(k, v) {
				new Foundation.Dropdown($(this));
			});
	}

	$('body').on('click tap', '[data-trigger="save-document"]', function () {
		$.post({
			'url': '/app/my-documents-upload',
			data: $('#edit-document').serialize(),
			dataType: 'json'
		}).done((data, textStatus, jqXHR) => {
			if (data.error) {
				return;
			} else {
				$('#my_documents').DataTable().ajax.reload();
			}
		}).always(() => {
			const $reveal = $('#reveal-edit-document');
			$reveal.foundation('close');
		});
	});

	$('body').on('click tap', '[data-menuitem-view]', function () {
		const id = $(this).data('id');
		if (!id) {
			return;
		}

		$('#reveal-edit-document').find('input[name="id"]').val(id);

		$.post({
			'url': '/app/myDocumentsMeta',
			data: { id },
			dataType: 'json'
		}).done((data, textStatus, jqXHR) => {
			if (data.error) {
				return;
			} else {
				const $reveal = $('#reveal-edit-document');
				$reveal.find('input[name="name"]').val(data.name);
				$reveal.find('input[name="desc"]').val(data.description);
				$reveal.find('#document-filename').text(data.filename);
				$reveal.find('#document-size').text(data.size);
				$reveal.find('#document-created').text(data.created);
				$reveal.foundation('open');
			}
		});
	});

	$('body').on('click tap', '[data-menuitem-remove]', function () {
		const id = $(this).data('id');
		if (!id) {
			return;
		}

		$('#reveal-remove-document').find('input[name="id"]').val(id);

		$.post({
			'url': '/app/myDocumentsMeta',
			data: { id },
			dataType: 'json'
		}).done((data, textStatus, jqXHR) => {
			if (data.error) {
				return;
			} else {
				const $reveal = $('#reveal-remove-document');
				$reveal.find('#document-name').text(data.name);
				$reveal.find('#document-desc').text(data.description);
				$reveal.find('#document-filename').text(data.filename);
				$reveal.find('#document-size').text(data.size);
				$reveal.find('#document-created').text(data.created);
				$reveal.foundation('open');
			}
		});
	});

	$('body').on('click tap', '[data-trigger="remove-document"]', function () {
		$.post({
			'url': '/app/myDocumentsRemove',
			data: $('#remove-document').serialize(),
			dataType: 'json'
		}).done((data, textStatus, jqXHR) => {
			if (data.error) {
				return;
			} else {
				$('#my_documents').DataTable().ajax.reload();
			}
		}).always(() => {
			const $reveal = $('#reveal-remove-document');
			$reveal.foundation('close');
		});
	});

	$('body').on('click tap', 'input[name="myDocumentsUpload"]', function(event) {
		event.preventDefault();

		let $button = $(this);
		$button.data('text', $button.val()).prop('disabled', true).val(i18n.__('labels.uploading'));

		let formData = new FormData(document.getElementById('my-document-form'));

		$.post({
			url: '/app/my-documents-upload',
			dataType: 'JSON',
			enctype: 'multipart/form-data',
			processData: false,
			contentType: false,
			data: formData
		}).done((data, textStatus, jqXHR) => {
			if (data.error) {
				$('#upload-error').html(data.error).removeClass('hide');
			} else {
				// $('#upload-success').removeClass('hide');
				$('#my_documents').DataTable().ajax.reload();
				$('#my-document-form').find('input').each(function() {
					$(this).val('');
				})
				$('#reveal-upload-document').foundation('close');
			}
		}).always(() => {
			let $button = $('input[name="myDocumentsUpload"]');
			$button.val($button.data('text'));
			let $uploader = $('#my-document-form').find('.custom-uploader');
			$uploader.find('.upload-dragdrop').removeClass('dragover');
			$uploader.find('.upload-filename').empty();
		});
	});

	$('body').on('custom-uploader', 'input[name="my-document"]', function (e, basename, fullpath) {
		$('#upload-error').addClass('hide');
		$('#upload-success').addClass('hide');
		$('input[name="myDocumentsUpload"]').prop('disabled', false);
	});

	$('body').on('click tap', 'a.disabled', function(e) {
		e.stopPropagation();
		return false;
	});

	currencySymbol = $('input[name=currencySymbol]').length ? $('input[name=currencySymbol]').val() : null;
	initialPrice = $('input[name=permitPrice]').length ? $('input[name=permitPrice]').val() : null;
	smsFee = $('input[name=smsCost]').length ? $('input[name=smsCost]').val() : null;
	reminderPrice = $('input[name=reminderPrice]').length ? $('input[name=reminderPrice]').val() : null;
	finalPrice = scPrice = initialPrice;

	if (initialPrice == 0) {
		let price = $('[data-target=permit-price]').text();
		if (price == i18n.__('variable')) {
			initialPrice = i18n.__('variable');
		}
	}

	if (/app\/purchase/.test(document.location.pathname)) {

		let isModal = $('#location-permit-detail').hasClass('reveal');

		let $validFrom = $('#permit-valid-from-date');
		if ($validFrom.length) {

			let minDate = $validFrom.attr('min');
			let maxDate = $validFrom.attr('max');
			let forcedDate = $validFrom.data('forced');
			let validFromFlatpickr = $('#permit-valid-from-date')[0]._flatpickr;

			if (forcedDate) {
					validFromFlatpickr.setDate(forcedDate);
					$('.datepicker').prop('disabled', true);
			} else {
				if (minDate) {
					validFromFlatpickr.set('minDate', minDate);
					$('.datepicker.flatpickr-mobile').attr('min', minDate);
				}
				if (maxDate) {
					validFromFlatpickr.set('maxDate', maxDate);
					$('.datepicker.flatpickr-mobile').attr('max', maxDate);
				}
			}

			// Keep time updated to current time unless manually set
			trackCurrentTime('input[name="validFromDate"]', 'input[name="validFromTime"]')
		}

		$('body').on('keydown keyup', 'textarea[name="supporting-information"]', function(e) {
			let text = $(this).val();
			if (text.length > 200) {
				text = text.substr(0, 200);
				$(this).val(text);
			}
			let remaining = 200 - text.length;
			$('#support-chars').html(i18n.__n('labels.characters.remaining', remaining));
		});
	}

	$('body').on('click', 'input[name="payeng-proceed"]', function() {
		let paymentRef = $(this).attr('data-ref');
		let descr = $(this).attr('data-desc');
		let currency = $(this).attr('data-cur');
		let booking = $(this).attr('data-add');
		let button = $(this);

		$.ajax({
			url: '/applicant/payengPrepareTransaction',
			type: 'POST',
			dataType: 'json',
			data: { paymentRef, descr, currency, booking },

			beforeSend: function() {
				button.prop('disabled', true).val(i18n.__('labels.loading'));
			},
			success: function(data) {
				if (data.error == false) {
					location.href = data.formUrl;
				} else {
					button.prop('disabled', false).val(i18n.__('buttons.continue'));
					alert(i18n.__('errors.failed'));
				}
			}
		});
	});

	$('body').on('click', '#cancel-auto-renewal', function() {
		let tableId = $(this).attr('data-id');
		let tableName = $(this).attr('data-table');
		let button = $(this);

		$.ajax({
			url: '/applicant/cancelAutoRenewal/'+tableId+'/'+tableName,
			type: 'POST',
			dataType: 'json',

			beforeSend: function() {
				button.prop('disabled', true);
			},
			success: function(data) {
				if (data.error == true) {
					button.prop('disabled', false);
					alert(data.msg);
				} else {
					if ($('#view-booking').length) {
						window.location.reload();
					}
					$('body').find('#cancel-success').html(i18n.__('portal.mybookings.auto.renew.cancelled')).show();
				}
			}
		});
	});

	function submitCancelApplication(permitId) {
		$.ajax({
			url: '/applicant/addPermitCancellationRequest/',
			type: 'POST',
			dataType: 'json',
			data: { permitId },

			success: function() {
				window.location.reload();
			}
		});
	}

	$('body').on('click tap', '[data-trigger="permit-cancel-approval-submit"]', function(event) {
		event.preventDefault();

		let button = $(this);
		button.prop('disabled', true);
		let $form = $('#cancel-permit-approval-form');
		let permitId = $form.find('input[name="bookingId"]').val();
		let permitType = $form.find('input[name="permitType"]').val();

		// if there's questionnaire submit to approval without extra processing
		if ($('[data-target="permit-cancel-questionnaire-container"]').html() === "") {
			submitCancelApplication(permitId);
			return;
		}

		let cartArr = [];

		let formData = new FormData(document.getElementById('cancel-permit-approval-form'));
		let cart  = {};

		cart.permitId = permitId;
		cart.locationId = $form.find('input[name="locationId"]').val();
		cart.locationType = $form.find('input[name="locationType"]').val();
		cart.application = {};

		for (var entry of formData.entries()) {
			if (!cart.application.hasOwnProperty(entry[0])) {
				cart.application[entry[0]] = entry[1];
			} else {
				var entryArr = [];

				// If its already an array loop it and push elements
				if (Array.isArray(cart.application[entry[0]])) {
					for (var subEntry of cart.application[entry[0]]) {
						entryArr.push(subEntry);
					}
				} else {
					entryArr.push(cart.application[entry[0]]);
				}

				entryArr.push(entry[1]);
				cart.application[entry[0]] = entryArr;
			}
		}

		// Add any missing checkboxes because they weren't checked
		$("#cancel-permit-approval-form input:checkbox").each(function() {
			if (!cart.application.hasOwnProperty(this.name)) {
				cart.application[this.name] = '';
			}
		});
		cartArr.push(cart);
		formData.append('cart', JSON.stringify(cartArr));
		formData.append('type', permitType);
		formData.append('purpose', 'Cancellation');

		$.when(
			submitQuestionnaire(formData)
		).done(function(data) {
			if (data == 'logout') {
				logoutInactive({ logout:true });
				return;
			}

			let $errors = $('#cancel-permit-questionnaire-errors');

			if (data.error) {
				// Show error messages
				$errors.find('.callout').html(data.msg);
				$errors.show();

				// Add error labels
				$.each(data['error-list'], function(ele, msg) {
					$('body').find(`[data-target="${ele}-error-label"]`).addClass('is-invalid-label');
				});
				button.prop('disabled', false);
				return;
			}
			// submit for approval
			submitCancelApplication(permitId);

		}).fail(function(xhr, textStatus, errorThrown) {
			button.prop('disabled', false);
			console.warn(errorThrown);
		});
	});

	$('#reveal-permit-reminders')
		.on('closed.zf.reveal', function (e) {
			$('.no-reminders').hide();
			$('.reminders-table').hide().empty();
		});

	$('#reveal-scratchcard-reminders')
		.on('closed.zf.reveal', function (e) {
			$('.no-reminders').hide();
			$('.reminders-table').hide().empty();
		});

	$('body').on('click', '[data-open="reveal-permit-reminders"]', function() {
		let id = $(this).data('id');

		$('#reveal-permit-reminders').data('id', id);
		// $('#reveal-permit-reminders').find('div.reminders-table').empty();

		$.get({
			url: `/applicant/permitReminderTable/${id}`,
			dataType: 'json',
		}).done(handleReminderTable);
	});

	$('body').on('click', '[data-open="reveal-scratchcard-reminders"]', function() {
		let id = $(this).data('id');
		let credits = $(this).data('credits');
		let used = $(this).data('used');

		$('#reveal-scratchcard-reminders').data('id', id);
		$('#reveal-scratchcard-reminders').data('credits', credits);
		$('#reveal-scratchcard-reminders').data('used', used);
		// $('#reveal-permit-reminders').find('div.reminders-table').empty();

		$.get({
			url: `/applicant/scratchcardReminderTable/${id}`,
			dataType: 'json',
		}).done(handleReminderTable);
	});

	$('body').on('change', 'select[name="newThreshold"]', function() {
		let $parent;
		if ($('#view-booking').length) {
			$parent = $('#reminders-table-container');
		} else {
			$parent = $('#reveal-scratchcard-reminders');
		}
		const $reveal = $(this).closest('div.reveal');
		const $newTime = $reveal.find('input[name="newTime"]');
		var min, max;
		if ($(this).val() == '') {
			$('#b4-remaining').show();
			$('#b4-expiry').hide();
			const credits = $parent.data('credits');
			min = 0;
			max = credits - 1;
			$newTime.attr('min', min);
			$newTime.attr('max', max);

		} else {
			$('#b4-remaining').hide();
			$('#b4-expiry').show();
			min = 1;
			max = Number.MAX_SAFE_INTEGER;
			$newTime.attr('min', min);
			$newTime.removeAttr('max');
		}

		const time = $newTime.val();
		$newTime.val(Math.min(Math.max(min, time), max));
	});

	$('body').on('click tap', '[data-trigger="edit-reminder"]', function() {
		let reminderId = $(this).data('id');
		let type = $(this).data('type');
		let time = $(this).data('time');
		let threshold = $(this).data('threshold');
		let indefinite = $(this).data('indefinite');

		let isPermit = false;
		let reveal = false;

		if ($('#view-booking').length) {
			reveal = 'view-booking';
			isPermit = $('input[name="bookingType"]').val() == 'permit' ? true : false;
		} else {
			let $reveal = $(this).closest('div.reveal');
			reveal = $reveal.attr('id');
			isPermit = reveal == 'reveal-permit-reminders';
		}

		let isScratchcard = (isPermit === false);
		let isCredits = isScratchcard && (threshold == null || threshold.length == 0);

		$('#reveal-edit-reminder').data('id', reminderId);
		$('#reveal-edit-reminder').find('select[name="newType"]').val(type)[0].sumo.reload();
		const $newTime = $('#reveal-edit-reminder').find('input[name="newTime"]');
		const $newThreshold = $('#reveal-edit-reminder').find('select[name="newThreshold"]');
		const superSumo = $newThreshold.SuperDuperSumo();

		if (isCredits) {
			$newThreshold[0].sumo.selectItem('');
		} else {
			$newThreshold[0].sumo.selectItem(threshold);
		}

		$newTime.val(time);
		$newThreshold.find('option').each(function (index, value) {
			superSumo.showItem(index);
		});
		$newThreshold.change();

		if (indefinite) {
			[0, 1, 2].forEach(index => {
				superSumo.hideItem(index);
			});
		} else {
			if (isPermit) {
				superSumo.hideItem(3);
			}
		}

		$('#reveal-edit-reminder')
			.data('parent', reveal)
			.foundation('open');
	});

	$('body').on('click', '[data-trigger="save-reminder"]', function() {
		let button = $(this);
		let type = $('#reveal-edit-reminder').find('select[name="newType"]').val();
		let number = $('#reveal-edit-reminder').find('input[name="newTime"]').val();
		let threshold = $('#reveal-edit-reminder').find('select[name="newThreshold"]').val();
		let reminderId = $('#reveal-edit-reminder').data('id');

		let endpoint, data, bookingId, isPermit;

		const $reveal = $(this).closest('div.reveal');
		const parent = $reveal.data('parent');
		const $parent = $(`#${parent}`);

		if (parent == 'view-booking') {
			isPermit = $('input[name="bookingType"]').val() == 'permit' ? true : false;
			bookingId = $('input[name="bookingId"]').val();
		} else {
			isPermit = parent == 'reveal-permit-reminders';
			bookingId = $parent.data('id');
		}

		if (isPermit) {
			endpoint = 'editPermitReminderApplicant';
		} else {
			endpoint = 'editScratchcardReminderApplicant';
		}

		data = { type, number, threshold, bookingId, reminderId };

		$.ajax({
			url: '/applicant/' + endpoint,
			type: 'POST',
			dataType: 'json',
			data: data,
			beforeSend: function () {
				button.prop('disabled', true);
				$('body').find('#reminder-edit-error').html('').hide();
			},
			success: function(data) {
				if (data.error == true) {
					$('body').find('#reminder-edit-error').html(data.msg).show();
					button.prop('disabled', false);
				} else {
					if (parent == 'view-booking') {
						$('body').find('#reminders-table-container').html(data.html).foundation();
					} else {
						handleReminderTable(data);
					}
					$('#reveal-edit-reminder').foundation('close');
					button.prop('disabled', false);
				}
			}
		});
	});

	$('body').on('click', '[data-trigger="remove-reminder"]', function() {
		let parent;
		if ($('#view-booking').length) {
			parent = 'view-booking';
		} else {
			parent = $(this).closest('div.reveal').attr('id');
		}
		$('#reveal-delete-reminder')
			.data('id', $(this).data('id'))
			.data('parent', parent)
			.foundation('open');
	});

	$('body').on('click', '[data-trigger="delete-reminder-confirm"]', function() {
		let button = $(this);
		let reminderId = $('#reveal-delete-reminder').data('id');

		let data, permitId, scratchcardId, parent, isPermit;

		const $reveal = $(this).closest('div.reveal');
		parent = $reveal.data('parent');

		const $parent = $(`#${parent}`);

		if (parent == 'view-booking') {
			isPermit = $('input[name="bookingType"]').val() == 'permit' ? true : false;
		} else {
			isPermit = parent == 'reveal-permit-reminders';
		}

		if (isPermit) {
			permitId = parent == 'view-booking' ? $('input[name="bookingId"]').val() : $parent.data('id');
			data = { permitId, reminderId };
		} else {
			scratchcardId = parent == 'view-booking' ? $('input[name="bookingId"]').val() : $parent.data('id');
			data = { scratchcardId, reminderId };
		}

		button.prop('disabled', true);
		$.post({
			url: '/applicant/deletePermitReminderApplicant',
			data: data,
			dataType: 'json',
		}).done((response) => {
			if (parent == 'view-booking') {
				$('body').find('#reminders-table-container').html(response.html).foundation();
			} else {
				handleReminderTable(response);
			}
			$('#reveal-delete-reminder').foundation('close');
		}).always(() => {
			button.prop('disabled', false);
		});
	});

	$('body').on('change', 'input[name="creditNo"]', function() {
		let inputElement = $(this);
		let enteredValue = parseInt(inputElement.val());
		let minValue = parseInt(inputElement.attr('min'));
		let maxValue = parseInt(inputElement.attr('max'));

		if (isNaN(enteredValue)) {
			inputElement.val(minValue);
		} else if (enteredValue < minValue) {
			inputElement.val(minValue);
		} else if (enteredValue > maxValue) {
			inputElement.val(maxValue);
		} else {
			inputElement.val(enteredValue);
		}
		calculateCreditExpiry();
	});

	$('body').on('change', 'input[name="startDate"], input[name="startTime"]', function() {
		calculateCreditExpiry();
	});

	initializeFilter('sc-permit');
});

/**
 * Adds an interval to a date, using moment library.
 *
 * @param  Date    date   The start date.
 * @param  integer number The period number.
 * @param  integer period The id of the period duration.
 * @return Date
 */
function addInterval(date, number, period)
{
	const MAPPING = {'1': 'w', '3': 'M', '4': 'Q', '5': 'y', '6': 'h', '7': 'd'};
	if (period == PERIOD.FORTNIGHT) {
		number *= 2;
		period = '1';
	}
	return moment(date).add(number, MAPPING[period.toString()]).toDate();
}

function calculateCreditExpiry() {
	let usedCredits = $('body').find('input[name="creditNo"]').val();
	let durationPeriod = $('body').find('#cred-period').val();
	let durationLength = $('body').find('#cred-number').val();
	let date = $('body').find('input[name="startDate"]').val();
	let time = $('body').find('input[name="startTime"]').val();
	let creditEndTime = $('body').find('#creditEndTime').val();
	let startDate = date + ' ' + time;
	let expiryDate = '---';

	if (!usedCredits) {
		usedCredits = 1;
	}

	if (date && time && usedCredits >= 1) {
		// multiply the duration length by used credits
		let totalDurationLength = durationLength * usedCredits;
		// calculate the expiry date using the start date and the credit duration
		expiryDate = moment(addInterval(startDate, totalDurationLength, durationPeriod));
		// taking into account a fixed end time
		if (creditEndTime.length) {
			let parts = creditEndTime.split(':');
			expiryDate.set('hour', parts[0]).set('minute', parts[1]);
			// LEAVING FOR NOW, THIS WOULD ADD A DAY ONTO THE EXPIRY, IF THE END TIME IS LESS THAN THE START TIME
			// const startDateTime = moment(startDate, 'YYYY-MM-DD HH:mm');
			// const startMinutes = startDateTime.hour() * 60 + startDateTime.minute();
			// const expiryMinutes = Number(parts[0]) * 60 + Number(parts[1]);
			// if (expiryMinutes < startMinutes) {
			// 	expiryDate.add(1, 'd');
			// }
		}
		// get calculated date as string
		expiryDate = expiryDate.format('DD/MM/YYYY HH:mm');
	}
	$('body').find('#calculated-expiry-date').html(expiryDate);
}

function handleReminderTable(response)
{
	if (response.error) {
		$('.no-reminders').show();
		$('.reminders-table').empty().hide();
	} else {
		$('.no-reminders').hide();
		const $table = $(response.reveal).find('.reminders-table');
		$table
			.html(response.html)
			.show();
		$table.find('[data-tooltip]').each(function(k, v) {
			new Foundation.Tooltip($(this));
		})
		$table.find('.dropdown-pane').each(function(k, v) {
			new Foundation.Dropdown($(this));
		});
	}
	if (response.credits) {
		if ($('#view-booking').length) {
			$('#reminders-table-container').data('credits', response.credits);
			$('#reminders-table-container').data('used', response.used);
		} else {
			$(response.reveal).data('credits', response.credits);
			$(response.reveal).data('used', response.used);
		}
	}
	if (response.alert) {
		$('body').find('a.add-reminder')
			.data('indefinite', response.indefinite)
			.data('time', response.alert.alertTime)
			.data('threshold', response.alert.alertThreshold);
	} else {
		$('body').find('a.add-reminder')
			.data('time', 1)
			.data('threshold', 'day');
	}
}

function showPermit(id, description, name, price, sms, offsetText, renewable, isEmissions, emissionsTariffId, maxPurchaseable) {
	let $reveal = $('#location-permit-detail');
	let errors = false;

	smsFee = parseFloat(sms);
	currencySymbol = price.slice(0,1);
	initialPrice = finalPrice = i18n.__('variable');

	if (price != i18n.__('variable')) {
		initialPrice = finalPrice = price.slice(1, price.length);
	}

	$('#location-permit-detail').find('input[name="permitPrice"]').val(initialPrice);

	if (price != i18n.__('variable')) {
		reminderPrice = (parseFloat(price.substring(1, price.length)) + smsFee).toFixed(2);
	}

	if (isEmissions == 'true') {
		errors = handleEmissionsPermit(true, emissionsTariffId);
	} else {
		errors = handleEmissionsPermit(false, emissionsTariffId);
	}

	if (errors) {
		return;
	}

	let $vehiclesDropdown = $reveal.find('[data-target="vehicles-dropdown"]');

	if (maxPurchaseable > 1) {
		// build dropdown
		let purchaseDropdown = buildPurchaseDropdown(maxPurchaseable);
		$reveal.find('[data-target="purchase-count-container"]').html(purchaseDropdown);
		$vehiclesDropdown.hide();
		$('body').find('[data-target="emissions-duration-dropdown"]').hide();
	} else {
		$.when(
			getApplicantVehicles(id, isEmissions)
		).done(function(html) {
			$reveal.find('[data-target="purchase-count-container"]').html('');
			if (html != '') {
				let dropdown = '<label>' + i18n.__('labels.assign.vehicle') + html + '</label>';;

				$vehiclesDropdown.html(dropdown);
				if (!(/Mobi|Android/i.test(navigator.userAgent)) && !iOSiPadOS) {
					$vehiclesDropdown.find('select').SumoSelect(sumoConfig);
				}

				$vehiclesDropdown.show();


			}
		}).fail(function(xhr, textStatus, errorThrown) {
			console.warn(errorThrown);
		});
	}

	handlePermitTimes(id);

	$reveal.find('[data-target="tariff-description"]').html(description);
	$reveal.find('[data-target="tariff-name"]').html(name);
	$reveal.find('[data-target="permit-period"]').val(id);
	$reveal.find('[data-target="permit-emission-based"]').val(isEmissions);
	$reveal.find('[data-target="permit-price"]').html(price);
	$reveal.find('#permit-details-proceed-button').attr('data-id', id);

	if(offsetText != '') {
		$reveal.find('[data-target="offset-text"]').html(nl2br(offsetText));
	} else {
		$reveal.find('[data-target="offsetText"]').addClass('hide');
	}

	if (!(/Mobi|Android/i.test(navigator.userAgent)) && !iOSiPadOS) {
		$('.sumo-select').SumoSelect(sumoConfig);
	}

	$reveal.foundation('open');
}

const range = (start, end, length = end - start + 1) => Array.from({ length }, (_, i) => start + i);

function buildPurchaseDropdown(max, type = 'permit') {
	let html = '';

	html += '<label>' + i18n.__('labels.purchase.quantity');
	html += '<select name="purchase-dropdown-'+type+'" class="sumo-select">';
	html += '<option value="">' + i18n.__('buttons.select') + '</option>';
	$.each(range(1,max), function( index, value ) {
		html += '<option value="'+value+'">'+value+'</option>';
	});
	html += '</select>';
	html += '</label>';

	return html;
}

function nl2br (str, is_xhtml) {
	var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
	return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ breakTag +'$2');
}

function showScratchcard(id, description, name, price, maxPurchaseable) {
	let $reveal = $('#location-scratchcard-detail');

	if (maxPurchaseable > 1) {
		let purchaseDropdown = buildPurchaseDropdown(maxPurchaseable, 'scratchcard');
		$reveal.find('[data-target="purchase-count-container-sc"]').html(purchaseDropdown).show();
	} else {
		$reveal.find('[data-target="purchase-count-container-sc"]').html('').hide();
	}

	scPrice = price;

	$reveal.find('[data-target="tariff-description"]').html(description);
	$reveal.find('[data-target="tariff-name"]').html(name);
	$reveal.find('#scratchcard-details-proceed-button').attr('data-id', id);
	$reveal.find('[data-target="scratchcard-price"]').html(price);

	if (!(/Mobi|Android/i.test(navigator.userAgent)) && !iOSiPadOS) {
		$('.sumo-select').SumoSelect(sumoConfig);
	}

	$reveal.foundation('open');
}

function handlePermitTimes(value) {
	// check the tariff period first
	let data = tariffPeriodLookup(value);
	let customStartEnabled = data.customStartEnabled;

	// reset the elements
	$('#permit-total-price').html('<strong>0.00</strong>');
	$('#permit-expires-on').html('');
	$('[data-target="permit-period-valid-to"]').hide();

	// reserve the 'valid from' and 'valid to' elements
	let permitPeriodValidFrom = $('#permit-valid-from-date');
	let permitPeriodValidFromTime = $('#permit-valid-from-time');
	let permitPeriodValidFromTimeLabel = $('#label-hour-picker');

	permitPeriodValidFrom.fadeIn('fast');

	// if period is not custom, show the time input field as well
	if (customStartEnabled) {
		permitPeriodValidFromTime.addClass('display-inline').fadeIn('fast');
		permitPeriodValidFromTime.removeClass('hide');
		permitPeriodValidFromTime.siblings('.timepicker.flatpickr-mobile').removeClass('hide');
		permitPeriodValidFromTimeLabel.removeClass('hide');
	} else {
		permitPeriodValidFromTime.removeClass('display-inline').fadeOut('fast');
		permitPeriodValidFromTime.addClass('hide');
		permitPeriodValidFromTime.siblings('.timepicker.flatpickr-mobile').addClass('hide');
		permitPeriodValidFromTimeLabel.addClass('hide');
	}
}

function getApplicantVehicles(id, isEmissions) {
	return $.ajax({
		url: '/applicant/returnApplicantVehicleDropdown/',
		type: 'POST',
		dataType: 'HTML',
		data : { id, isEmissions }
	});
}

function setupScratchcardCart(id) {
	let validFromTime = $('#permit-valid-from-time').val();
	cart = [];
	let count = '1';

	if ($('body').find('[data-target="purchase-count-container-sc"]').is(':visible')) {
		count = $('body').find('select[name="purchase-dropdown-scratchcard"]').val();
		count = count != '' ? count : '1';
	}

	// Valid from time will be empty when hidden so lets default to current time
	if (validFromTime == '') {
		validFromTime = new Date().toLocaleTimeString('en-GB', {
			hour: 'numeric',
			minute: 'numeric'
		});

		$('#permit-form').find('input[name="validFromTime"]').val(validFromTime);
	}

	for (let i = 0; i < count; i++) {
		if ($('select[name="vehicle[]"]').length) {
			let single = {
				'id': Date.now().toString(36) + Math.random().toString(36).substr(2),
				'tariffId': id,
				'qty':  1,
				'name': 'Scratchcard',
				'locationId': $('body').find('input[name="locationId"]').val(),
				'locationType': $('body').find('input[name="locationType"]').val(),
				'options': {
					'vehicles': $('select[name="vehicle[]"]').val(),
					'site': $('body').find('input[name="locationId"]').val(),
					'validFromDate': $('#permit-valid-from-date').val(),
					'validFromTime': validFromTime,
					'smsReminder':  $('#location-scratchcard-detail').find('input[name="smsReminder"]').prop('checked') ? 1 : 0,
					'emailReminder': $('#location-scratchcard-detail').find('input[name="emailReminder"]').prop('checked') ? 1 : 0,
					'reminderTime': $('#reminderTime').val(),
					'reminderType': $('#reminderType').val(),
				},
			};
			if ($('#scratchcard-form input[name="tAndC"]').prop('checked')) {
				single.terms = true;
			}
			cart.push(single);
		} else {
			let single = {
				'id': Date.now().toString(36) + Math.random().toString(36).substr(2),
				'tariffId': id,
				'qty':  1,
				'name': 'Scratchcard',
				'locationId': $('body').find('input[name="locationId"]').val(),
				'locationType': $('body').find('input[name="locationType"]').val(),
				'options': {
					'site': $('body').find('input[name="locationId"]').val(),
					'validFromDate': $('#permit-valid-from-date').val(),
					'validFromTime': validFromTime,
					'smsReminder':  $('#location-scratchcard-detail').find('input[name="smsReminder"]').prop('checked') ? 1 : 0,
					'emailReminder': $('#location-scratchcard-detail').find('input[name="emailReminder"]').prop('checked') ? 1 : 0,
					'reminderTime': $('#reminderTime').val(),
					'reminderType': $('#reminderType').val(),
				},
			};
			if ($('#scratchcard-form input[name="tAndC"]').prop('checked')) {
				single.terms = true;
			}
			cart.push(single);
		}
	}
	return cart;
}

function setupPermitCart(id) {
	let validFromTime = $('#permit-valid-from-time').val();
	cart = [];

	let emissionsDurationId = null;
	if ($('body').find('select[name="emissionsDuration"]').length) {
		emissionsDurationId = $('body').find('select[name="emissionsDuration"]').val();
		// default time for emissions
		validFromTime = '00:00';
	} else {
		singlePrices = false;
	}

	let tariffDurationId = null;
	if ($('body').find('select[name="tariffDuration"]').length) {
		tariffDurationId = $('body').find('select[name="tariffDuration"]').val();
	} else {
		tariffDurationId = $('input[name="tariffDuration"]').val();
	}

	// Valid from time will be empty when hidden so lets default to current time
	if (validFromTime == '') {
		validFromTime = new Date().toLocaleTimeString('en-GB', {
			hour: 'numeric',
			minute: 'numeric'
		});

		$('#permit-form').find('input[name="validFromTime"]').val(validFromTime);
	}

	let validFromDate = ($('input[name="noDate"]').length && $('input[name="noDate"]').val() === '1') 
    ? new Date().toISOString().slice(0, 10) 
    : $('#permit-valid-from-date').val();

	if ($('select[name="vehicle[]"]').length) {
		$('select[name="vehicle[]"]').each(function() {
			let single = {
				'id': Date.now().toString(36) + Math.random().toString(36).substr(2),
				'tariffId': id,
				'locationId': $('body').find('input[name="locationId"]').val(),
				'locationType': $('body').find('input[name="locationType"]').val(),
				'qty': 1,
				'options': {
					'vehicles': $(this).val(),
					'validFromDate': validFromDate,
					'validFromTime': validFromTime,
					'smsReminder':  $('#location-permit-detail').find('input[name="smsReminder"]').prop('checked') ? 1 : 0,
					'emailReminder': $('#location-permit-detail').find('input[name="emailReminder"]').prop('checked'),
					'reminderTime': $('#reminderTime').val(),
					'reminderType': $('#reminderType').val(),
					'emissionsDurationId': emissionsDurationId,
					'tariffDurationId': tariffDurationId,
				}
			};
			if ($('#permit-form input[name="tAndC"]').prop('checked')) {
				single.terms = true;
			}
			cart.push(single);
		});
	} else {
		let single = {
			'id': Date.now().toString(36) + Math.random().toString(36).substr(2),
			'tariffId': id,
			'locationId': $('body').find('input[name="locationId"]').val(),
			'locationType': $('body').find('input[name="locationType"]').val(),
			'qty': 1,
			'options': {
				'vehicles': null,
				'validFromDate': validFromDate,
				'validFromTime': validFromTime,
				'smsReminder':  $('#location-permit-detail').find('input[name="smsReminder"]').prop('checked') ? 1 : 0,
				'emailReminder': $('#location-permit-detail').find('input[name="emailReminder"]').prop('checked'),
				'reminderTime': $('#reminderTime').val(),
				'reminderType': $('#reminderType').val(),
				'emissionsDurationId': emissionsDurationId,
				'tariffDurationId': tariffDurationId,
			}
		};
		if ($('#permit-form input[name="tAndC"]').prop('checked')) {
			single.terms = true;
		}
		cart.push(single);
	}

	//return cart;
}

function validatePermitForm(siteCurrencySymbol) {
	let result;

	let cartCount = 0;

	$.each(cart, function (index) {
		cartCount++;
		// Just in case this runs before calculate permit...
		if (cart[index].price === null || cart[index].price === undefined) {
			let currentPrice = $('body').find('[data-target="permit-price"]').html();
			cart[index].price = currentPrice.charAt(0) == siteCurrencySymbol ? currentPrice.substring(1) : currentPrice;
		}

		let data = {
			permitPrice : cart[index].price,
			permitPeriod : cart[index].tariffId,
			validFromDate : cart[index].options.validFromDate,
			validFromTime : cart[index].options.validFromTime,
			smsReminder : cart[index].options.smsReminder,
			locationId : cart[index].locationId,
			locationType : cart[index].locationType,
			vehicles :  cart[index].options.vehicles,
			terms : cart[index].terms,
			emissionsDuration : cart[index].options.emissionsDurationId,
			tariffDurationId : cart[index].options.tariffDurationId,
		};

		if (data.locationType == 'user') {
			let $supportingInformation = $('textarea[name="supporting-information"]');
			data.supportingInformation = $supportingInformation.length ? $supportingInformation.val() : null;
			data.userDefinedBays = $('select[name="dispensation-bays"]').val();
		}

		data.itemCount = cartCount;

		result = $.ajax({
			url: '/applicant/validatePermit',
			type: 'POST',
			dataType: 'JSON',
			data: data,
		});
	});

	return result;
}

function validateScratchcardForm(cart) {
	let result;
	$.each(cart, function (index) {
		result = $.ajax({
			url: '/applicant/validateScratchcard',
			type: 'POST',
			dataType: 'JSON',
			data: {
				scratchcardPack : cart[index].tariffId,
				terms: cart[index].terms,
				validFromDate : cart[index].options.validFromDate,
				validFromTime : cart[index].options.validFromTime,
				vehicles :  cart[index].options.vehicles,
			},
		});
	});
	return result;
}

function getQuestionnaire(cart, type) {
	let cartData = cart[0];
	return $.ajax({
		url: '/applicant/getQuestionnaire/',
		type: 'POST',
		dataType: 'HTML',
		data : { cartData, type }
	});
}

function submitQuestionnaire(formData) {
	return $.ajax({
		url: '/applicant/validateQuestionnaire',
		type: 'POST',
		dataType: 'JSON',
		enctype: 'multipart/form-data',
		processData: false,
		contentType: false,
		data: formData,
	});
}

function loadOrderSummary(type = 'permit') {
	$.when(
		saveOrder(type)
	).done(function(data) {
		let $errors = $('#questionnaire-errors');

		if (data.error) {
			$errors.find('.callout').html(data.msg);
			$errors.show();
			return;
		}

		$errors.html('').hide();
		if (['permit', 'emissions', 'dispensations'].includes(type)) {
			window.location = '/app/order-summary';
		} else {
			window.location = '/app/order-summary/sc';
		}
	}).fail(function(xhr, textStatus, errorThrown) {
		console.warn(errorThrown);
	});
}

function saveOrder(type) {
	let formData = new FormData();
	formData.append('cart', JSON.stringify(cart));

	return $.ajax({
		url: `/applicant/newOrderSummary/${type}`,
		type: 'POST',
		dataType: 'JSON',
		enctype: 'multipart/form-data',
		processData: false,
		contentType: false,
		data: formData,
	});
}

function checkPermitVehicleLimits(bookingId, bookingTable, action = 'change') {
	return $.ajax({
		url: '/applicant/checkPermitVehicleLimits',
		type: 'POST',
		dataType: 'json',
		data: {
			bookingId: bookingId,
			bookingTable: bookingTable,
			action: action
		}
	});
}

function getUnassignedVehiclesForMulti(bookingId, bookingTable) {
	return $.ajax({
		url: '/applicant/getUnassignedVehiclesForMulti',
		type: 'POST',
		dataType: 'html',
		data: {
			bookingId,
			bookingTable
		}
	});
}

/**
 * Reusable function to show vehicle information
 * @param  {[element]} $spinner  [Id of the loading spinner]
 * @param  {[element]} $select   [Id of the select box]
 * @param  {[integer]} vehicleId [The vehicle to show the information of]
 * @param  {[element]} $reveal   [Id of the reveal]
 * @param  {[string]}  data      [The data attr to load the HTML into]
 * @param  {[element]} $errors   [Id of the errors callout]
 * @return {[void]}
 */
function showVehicleInformation($spinner, $select, vehicleId, $reveal, data, $errors) {
	$spinner.show();
	//$select.prop('disabled', true);

	$.when(
		getVehicleSummary(vehicleId)
	).done(function(vehicle) {
		if (vehicle) {
			let html = vehicleSummaryTemplate(vehicle);
			$reveal.find(data).html(html).fadeIn(200);
		}
	}).always(function() {
		$select.prop('disabled', false);
		$spinner.hide();
	}).fail(function(xhr, textStatus, errorThrown) {
		$errors.html('Error: ' + errorThrown).fadeIn();
	});
}

/**
 * AJAX request to get vehicle summary information
 * @param  {[integer]} vehicleId [The id of the vehicle to get information for]
 * @return {[JSON]} [The JSON response from the AJAX]
 */
function getVehicleSummary(vehicleId) {
	return $.ajax({
		url: '/applicant/getVehicleSummary',
		type: 'POST',
		dataType: 'json',
		data: {
			vehicleId: vehicleId
		}
	});
}

function handleEmissionsPermit(enabled, emissionsTariffId) {
	let errors = false;

	if (enabled) {
		$('body').find('[data-target="emissions-duration-dropdown"]').show();
	} else {
		$('body').find('[data-target="emissions-duration-dropdown"]').hide();
	}

	$.when (
		getEmissionsTariffDurationDropdown(emissionsTariffId)
	).done(function(html) {
		$('body').find('[data-target="emissions-duration-dropdown"]').html(html);

		if (!(/Mobi|Android/i.test(navigator.userAgent)) && !iOSiPadOS) {
			$('body').find('select[name="emissionsDuration"]').SumoSelect(sumoConfig);
		}
	}).fail(function(xhr, textStatus, errorThrown) {
		console.warn(errorThrown);
		errors = true;
	});

	return errors;
}

function getEmissionsTariffDurationDropdown(emissionsTariffId, vehicleId = null) {
	return $.ajax({
		url: '/applicant/getEmissionsTariffDurationDropdown',
		type: 'POST',
		dataType: 'json',
		data: { emissionsTariffId, vehicleId },
	});
}

function calculateEmissionsCost(vehicles, duration, permitPeriod) {
	$.when(
		getEmissionsCost(vehicles, duration, permitPeriod)
	).done(function(data) {
		if (data.error) {
			logoutInactive(data);
			$('#permit-errors .callout').html(data.msg);
			$('#permit-errors').fadeIn();
			$('#permit-details-proceed-button').prop('disabled', true);
			return;
		}

		$('#permit-details-proceed-button').prop('disabled', false);

		$('#permit-errors').fadeOut();

		let permitPrice;
		singlePrices = data.singleCost;
		finalPrice = initialPrice = parseFloat(data.cost).toFixed(2);
		currencySymbol = data.currencyBefore;
		reminderPrice = (parseFloat(initialPrice) + parseFloat(smsFee));

		permitPrice = initialPrice;

		let displayPermitPrice = currencySymbol + permitPrice;

		$('#location-permit-detail').find('[data-target="permit-price"]').html(displayPermitPrice);
		$('#location-permit-detail').find('input[name="permitPrice"]').val(permitPrice);

	}).fail(function(xhr, textStatus, errorThrown) {
		console.warn(errorThrown);
	});
}

function getEmissionsCost(vehicles, duration, permitPeriod) {
	let locationId = $('body').find('input[name="locationId"]').val();
	singlePrices = [];

	return $.ajax({
		url: '/applicant/calculateEmissionsPermitCost',
		type: 'POST',
		dataType: 'json',
		data: { vehicles, duration, permitPeriod, locationId },
	});
}

function prefillVehicleForm(e) {
	$('#reveal-add-vehicle')
		.find('button').prop('disabled', true)
		.end()
		.find('h5').text(i18n.__('portal.myvehicles.edit'));
	$('input[name=vehicleName]').prop('disabled', true);
	$('select[name=vehicleType]').prop('disabled', true);
	$('input.vrm').prop('disabled', true);
	$('select[name=vehicleMake]').prop('disabled', true);
	$('input[name=vehicleModel]').prop('disabled', true);
	$('select[name=vehicleColour]').prop('disabled', true);
	$.post({
		url: '/applicant/getVehicleSummary',
		data: { vehicleId : $(this).attr('rel'), raw : true },
		dataType: 'json',
	}).done(function(data) {
		if (data === false) {
			$('#errors').html(i18n.__('errors.failed')).show();
			return;
		}
		$('[data-vehicleId]').val(data.id);
		if (data.name == undefined) {
			data.name = { 'value' : '' };
		}
		$('input[name=vehicleName]').prop('disabled', false).val(data.name.value);
		$('select[name=vehicleType]').prop('disabled', true).val(data.type)[0].sumo.reload();
		$('input.vrm').prop('disabled', true).val(data.vrm);
		$('select[name=vehicleMake]').prop('disabled', false).val(data.make)[0].sumo.reload();
		$('input[name=vehicleModel]').prop('disabled', false).val(data.model);
		$('select[name=vehicleColour]').prop('disabled', false).val(data.colour)[0].sumo.reload();
		$('#reveal-add-vehicle').find('button').prop('disabled', false);
	});
}

function closeVehicleForm(e) {
	$(this).find('h5').text($(this).data('title'));
	$('select[name=vehicleType]').prop('disabled', false);
	$('input.vrm').prop('disabled', false);
}

function sumoEm(el = 'body') {
	// if (isMobile) {
	// 	return;
	// }
	$(el).find('select.sumo-select').not('.SumoUnder').each(function() {
		$(this).SumoSelect(sumoConfig);
	});
	$(el).find('select.sumo-select-search').not('.SumoUnder').each(function() {
		$(this).SumoSelect(mergeSumoConfig({search: true}));
	});
}

/**
 *
 */
const clearAddressFields = () => {
	$('input[data-address]').each(function () {
		$(this).val('');
	});
	$('select[name="country"]')[0].sumo.selectItem('');
}

/**
 *
 */
const disableAddressFields = (disable = true) => {
	if (disableManualAddress) {
		disable = true;
	}
	if (disable) {
		$('input[data-address]').each(function () {
			let placeholder = $(this).attr('placeholder');
			$(this)
				.prop('disabled', true)
				.addClass('disabled')
				.data('placeholder', placeholder)
				.attr('placeholder', null);
		});
		$('select[name="country"]')[0].sumo.disable();
	} else {
		$('input[data-address]').each(function () {
			let placeholder = $(this).data('placeholder');
			$(this)
				.prop('disabled', false)
				.removeClass('disabled')
				.attr('placeholder', placeholder);
		});
		$('select[name="country"]')[0].sumo.enable();
	}
}

/**
 * Called when drawing a questionnaire to set up form elements
 */
function initialiseQuestionnaire()
{
	const flatdefaults = getFlatpickrDefaults();

	$.each($('body').find('.timepicker'), function (index, target) {
		target.flatpickr($.extend({}, flatdefaults, {
			enableTime: true,
			noCalendar: true,
			dateFormat: 'H:i',
			allowInput: false,
			time_24hr: true,
			disableMobile: true
		}));
	});

	$.each($('.datepicker'), function (index, target) {
		target.flatpickr($.extend({}, flatdefaults, {
			dateFormat: 'Y-m-d',
			altInput: true,
			altFormat: 'd/m/Y'
		}));
	});

	sumoEm('[data-questionnaire-container]');
}

function getEmailDomains()
{
	let domains = $('input[name="emailRestrictionDomains"]').val();
	if (domains.length == 0) {
		return null;
	}
	domains = JSON.parse(domains);
	return domains;
}

function getTacs() {
	let locationId = $('input[name="locationId"]').val();
	let locationType = $('input[name="locationType"]').val();
	return $.get({
		url: `/applicant/getTacs/${locationId}/${locationType}`,
		dataType: 'html'
	});
}

function vehicleUpdateModal(type, limit, availableChanges, approvalRequired, pendingUpdate) {
	let availableChangesInt;
	let error = 0;
	let classList = 'warning';
	let message = false;

	if (availableChanges == null || availableChanges == 'undefined') {
		availableChangesInt = Number.MAX_SAFE_INTEGER;
		availableChanges = null;
	} else {
		availableChangesInt = Number(availableChanges);
	}

	const messages = {
		'change' : [
			'portal.vehicles.change.errors.auth',
			'portal.vehicles.change.errors.only',
			'portal.vehicles.change.errors.remaining',
			'portal.vehicles.change.errors.existing'
		],
		'add' : [
			'portal.vehicles.add.errors.auth',
			'portal.vehicles.add.errors.only',
			'portal.vehicles.add.errors.remaining',
			'portal.vehicles.add.errors.existing'
		],
		'remove' : [
			'portal.vehicles.remove.errors.auth',
			'portal.vehicles.remove.errors.only',
			'portal.vehicles.remove.errors.remaining',
			'portal.vehicles.remove.errors.existing'
		],
	}

	if ((limit === 0) || (availableChangesInt < 1)) {
		message = i18n.__(messages[type][0]);
		classList = 'alert';
		error = 1;
	} else if (limit && availableChangesInt) {
		message = i18n.__n(messages[type][1], limit);
		if (limit > 1) {
			message += ' ';
			message += i18n.__n(messages[type][2], availableChangesInt);
		}
	}

	if (approvalRequired && pendingUpdate) {
		classList = 'alert';
		error = 2;
		message = i18n.__('portal.vehicles.change.errors.existing');
	}

	return { 'classList' : classList, 'message' : message, 'error' : error}
}

// Used by Google Autocomplete
window.selectFirstPredictionOnEnter = function(input) {
	var _addEventListener = (input.addEventListener) ? input.addEventListener : input.attachEvent;
	function addEventListenerWrapper(type, listener) {
		if (type == 'keydown') {
			var orig_listener = listener;
			listener = function(event) {
				var suggestion_selected = $(".pac-item-selected").length > 0;
				if (event.which == 13 && !suggestion_selected) {
					var simulated_downarrow = $.Event("keydown", {
						keyCode: 40,
						which: 40
					});
					orig_listener.apply(input, [simulated_downarrow]);
				}
				orig_listener.apply(input, [event]);
			};
		}
		_addEventListener.apply(input, [type, listener]);
	}
	if (input.addEventListener)
		input.addEventListener = addEventListenerWrapper;
	else if (input.attachEvent)
		input.attachEvent = addEventListenerWrapper;
}

// Observes the autocomplete and automatically adds the .pac-item-faux class to the first element
window.autocompleteObserver = function() {

	const observer = new MutationObserver(
		function(mutationList, observer) {
			mutationList.forEach((mutation) => {
				if (mutation.type === 'childList') {
					$.each(mutation.addedNodes, function(k, node) {
						if ($(node).hasClass('pac-container')) {
							pacContainerObserver.observe(node, { attributes: true, childList: true, subtree: true });
							observer.disconnect();
						}
					})
				}
			});
		}
	);
	observer.observe($('body')[0], { attributes: false, childList: true, subtree: false });

	const pacContainerObserver = new MutationObserver(
		function(mutationList, observer) {
			if ($('.pac-item-selected').length) {
				$('.pac-item').removeClass('pac-item-faux');
			} else {
				$('.pac-item:first').addClass('pac-item-faux');
			}
		}
	);
}

window.getGoogleBounds = function() {
	if ($('input[name="bounds"]').length) {
		let latlngBounds = JSON.parse($('input[name="bounds"]').val());
		return new google.maps.LatLngBounds(
			new google.maps.LatLng({ lat: Number(latlngBounds.south), lng: Number(latlngBounds.west) }),
			new google.maps.LatLng({ lat: Number(latlngBounds.north), lng: Number(latlngBounds.east) })
		);
	}
	return null;
}

/**
 * If we have a datatable in page
 */
if (jQuery.fn.dataTable != undefined) {

	/**
	 * Renders a document's applications.
	 */
	jQuery.fn.dataTable.render.applications = function (data, type, row) {
		if (!data) {
			data = [];
		}

		let params = { id: row.id, total: data.length, class: 'listo' };

		const labels = {permits: i18n.__('labels.permit'), scratchcard_credits: i18n.__('labels.scratchcard')};

		switch (type) {
			case 'display':
				if (params.total) {
					params.open = '<button class="text-inform-wcag" data-toggle="listoapplications_' + row.id + '">';
					params.close = '</button>';
					params.label = i18n.__n(`portal.mydocuments.applications`, params.total);
					const applications = data.map(application => {
						const submittedAt = jQuery.fn.dataTable.render._datetime.call('DD/MM/YYYY HH:mm', application.submittedAt, type);
						const label = labels[application.table];
						return i18n.__(
							'portal.mydocuments.application',
							{label: label, id: application.tableId, date: submittedAt }
						);
					});
					params.info = applications.join('<br>');
				}
				return params.total == 0
					? jQuery.fn.dataTable.render.NA
					: jQuery.fn.dataTable.render._untokenise($('#listoapplications').html(), params);
			case 'filter':
				if (params.total) {
					const applications = data.map(application => {
						const submittedAt = jQuery.fn.dataTable.render._datetime.call('DD/MM/YYYY HH:mm', application.submittedAt, type);
						const label = labels[application.table];
						return `${label} ${application.tableId}`;
					});
					return applications.join('|');
				} else {
					return '';
				}
			case 'sort':
				return params.total;
			default:
				return params.total == 0 ? i18n.__('na') : i18n.__n(`portal.mydocuments.applications`, params.total);
		}
	};
}
