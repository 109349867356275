export function trackCurrentTime(dateSelector, timeSelector)
{
    // keep track of whether the applicant has manually set the date and time
    const $startTime = $(timeSelector);
    if ($startTime.length == 0) {
        return;
    }
    const $startDate = $(dateSelector);
    $startTime.data({ 'manual':false, 'open':false });

    // Add event handler to the time picker
    // to set "open" flag when modal is open, and to unset it when closed
    const timePickr = $startTime[0]._flatpickr;
    if (typeof timePickr != 'undefined') {
        timePickr.set(
            'onOpen',
            function(selectedDates, dateStr, instance) {
                $startTime.data('open', true);
            }
        );
        timePickr.set(
            'onClose',
            function(selectedDates, dateStr, instance) {
                $startTime.data('open', false);
            }
        );

        // Set "manual" flag to true if user changes either the date or time
        $startDate.on('change',function(e) {
            $startTime.data('manual', true);
            clearTimeout(window.startTimeTimer);
        });
        $startTime.on('change',function(e) {
            $(this).data('manual', true);
            clearTimeout(window.startTimeTimer);
        });

        // Runs every second to update the time
        window.startTimeTimer = setInterval(
            () => {
                // const $startTime = $('input[name="startTime"]');
                if ($startTime.data('open') || $startTime.data('manual')) {
                    return;
                }
                $startTime[0]._flatpickr.setDate(new Date());

            },
            1000
        );
    }
}

